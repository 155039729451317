import { useNavigate, useParams } from "react-router-dom";
import ImagePicker from "../../components/image-picker/image-picker.component";
import AddNewSection from "../../components/add-new-section/add-new-section.component";
import VideoPicker from "../../components/video-picker/video-picker.component";
import CurrencyInput from "react-currency-input-field";
import { useEffect, useState } from "react";
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  FormikHelpers,
} from "formik";
import * as Yup from "yup";
import GlimpseInputBox from "components/input/formik-input";
import { CareerInfo, IAnswer } from "../../interfaces/CareerInterface";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createCareer,
  getCareerForEdit,
  updateCareer,
} from "../../services/career.service";
import {
  displayError,
  displaySuccess,
  displayWarning,
} from "../../components/Toast/toast.component";
import { ProsCons, References, Status } from "../../enums/glimps.enums";
import Loading from "components/loading/loading.component";
import { makePostReady } from "utills/makePostReady";
import InputError from "components/input-error/input-error";
import GlimpseTextAreaBox from "components/textarea/formik-textarea";
import GlimpseCheckbox from "components/toggle/toggle.component";

const defaultData: CareerInfo = {
  id: null,
  status: Status.Draft,
  icon: {
    id: null,
    is_changed: false,
  },
  icon_url: "",
  name: "",
  description: "",
  superpower: "",
  contribution: {
    id: null,
    is_changed: false,
  },
  contribution_url: "",
  salaries: {
    advance: null,
    median: null,
    starting: null,
  },
  references: [
    {
      link: "",
      title: References.DidYouKnow,
    },
    {
      link: "",
      title: References.DidYouKnow,
    },
    {
      link: "",
      title: References.DidYouKnow,
    },
    {
      link: "",
      title: References.DidYouKnow,
    },
    {
      link: "",
      title: References.Salary,
    },
    {
      link: "",
      title: References.CareerDemand,
    },
    {
      link: "",
      title: References.ImpactQuote,
    },
  ],
  trends: [
    {
      description: "",
      title: "",
    },
  ],
  educations: [
    {
      title: "High School Diploma",
      duration: 4,
      is_enabled: false,
      order: 1,
      type: null,
      description: "",
    },
    {
      title: "Associate's Degree",
      duration: 2,
      is_enabled: false,
      order: 2,
      type: null,
      description: "",
    },
    {
      title: "Bachelor's Degree",
      duration: 4,
      is_enabled: false,
      order: 3,
      type: null,
      description: "",
    },
    {
      title: "Graduate Degree",
      duration: 4,
      is_enabled: false,
      order: 4,
      type: null,
      description: "",
    },
    {
      title: null,
      duration: null,
      is_enabled: false,
      order: 5,
      type: null,
      description: "",
    },
  ],
  certificates: {
    is_enabled: false,
    certificate_list: [
      {
        duration: 0,
        order: 1,
        title: "",
        type: null,
      },
    ],
  },
  expert_voices: [
    {
      order: 1,
      voice: { id: null, is_changed: false, is_thumbnail_changed: false },
      voice_thumbnail: "",
      voice_url: "",
    },
  ],
  days_in_life: [
    {
      order: 1,
      description: "",
      image: {
        id: null,
        is_changed: false,
      },
      image_url: "",
      title: "",
    },
  ],
  pros_cons: {
    pros: [
      {
        title: "",
        type: ProsCons.Pros,
        order: 1,
      },
    ],
    cons: [
      {
        title: "",
        type: ProsCons.Cons,
        order: 2,
      },
    ],
  },
  did_you_knows: [
    {
      icon: {
        id: null,
        is_changed: false,
      },
      icon_url: "",
      order: 1,
      title: "",
    },
  ],
  impacts: [
    {
      author: "",
      description: "",
    },
  ],
  quizzes: [
    {
      question: "",
      answers: [
        {
          answer: "",
          is_correct: false,
        },
        {
          answer: "",
          is_correct: false,
        },
        {
          answer: "",
          is_correct: false,
        },
      ],
    },
  ],
};

const CareerFormScreen: React.FC = () => {
  let { param } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [careerInfo, setCareerInfo] = useState<CareerInfo>(defaultData);
  const [enableButtons, setEnableButtons] = useState<boolean>(param ? false : true)
  const { data: fetchedData, status } = useQuery({
    queryKey: ["GetCareerForEdit", param],
    queryFn: () => getCareerForEdit(param as string),
    enabled: !!param,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  useEffect(() => {
    if (fetchedData && status === "success") {
      setCareerInfo(fetchedData);
      setEnableButtons(true)
    }
  }, [fetchedData, status]);

  const submitCareer = useMutation({
    mutationFn: (data: CareerInfo) => {
      if (data.id) {
        return updateCareer(data);
      } else {
        return createCareer(data);
      }
    },
    retry: 0,
    onSuccess: (data: any) => {
      displaySuccess("Career saved");
      queryClient.invalidateQueries({ queryKey: ['GetCareerById', 'getAllCareer'] })
    },
  });

  const handleSubmit = (data: CareerInfo) => {
    // const modifiedData: CareerInfo = makePostReady({ ...data });
    const modifiedData: CareerInfo = { ...data };
    modifiedData.educations.forEach((x) => {
      if (x.order === 1) {
        if (x.type) {
          x.is_enabled = true;
        }
      }
      Number(x.duration);
      return;
    });
    submitCareer.mutateAsync(modifiedData);
  };

  const isLoading = submitCareer.isPending;

  const prosConsSchema = Yup.object({
    title: Yup.string().required("This field is Required"),
  });

  const draftValidationSchema = Yup.object({
    name: Yup.string().required("Career Name is required..!"),
    superpower: Yup.string().required("Superpower is required..!"),
    icon_url: Yup.string().required("Career Icon is required..!"),
  });

  const daysInLifeSchema = Yup.object({
    image_url: Yup.string().required("Image is required"),
    title: Yup.string().required("Subtitle is required"),
    description: Yup.string().required("Name is required"),
  });

  const exportVoiceSchema = Yup.object({
    voice_url: Yup.string().required("Expert voices cover video is Required"),
    voice_thumbnail: Yup.string().required(
      "Expert voices thumbnail is Required"
    ),
  });

  const didYouKnowSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    icon_url: Yup.string().required("Icon is required..!"),
  });

  const careerTrendsSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required..!"),
  });

  const impactOnOtherSchema = Yup.object({
    author: Yup.string().required("Author is required"),
    description: Yup.string().required("Description is required..!"),
  });

  const answersSchema = Yup.object({
    answer: Yup.string().required("Answer is required"),
  });

  // const educationSchema = Yup.object({
  //   title : Yup.string().when('is_enabled', {
  //     is: true, 
  //     then: Yup.string().required('Education title is required'),
  //     otherwise: Yup.string(),
  // })
  // });

  const quizzesSchema = Yup.object({
    question: Yup.string().required("Question is required"),
    answers: Yup.array()
      .of(answersSchema)
      .required("Must have Answers")
      .min(3, "Minimum of 3 answers are required"),
  });

  const publishValidationSchema = Yup.object().shape({
    name: Yup.string().required("Career Name is required..!"),
    superpower: Yup.string().required("Superpower is required..!"),
    icon_url: Yup.string().required("Career Icon is required..!"),
    description: Yup.string().required("Description is required..!"),
    contribution_url: Yup.string().required(
      "Contribution Image is required..!"
    ),
    salaries: Yup.object({
      starting: Yup.string().required("Starting Salary is required..!"),
      median: Yup.string().required("Median Salary is required..!"),
      advance: Yup.string().required("Advance Salary is required..!"),
    }),

    pros_cons: Yup.object({
      pros: Yup.array()
        .of(prosConsSchema)
        .required("Must have Pros & Cons")
        .min(5, "Minimum of 5 Pros & Cons required"),

      cons: Yup.array()
        .of(prosConsSchema)
        .required("Must have Pros & Cons")
        .min(5, "Minimum of 5 Pros & Cons required"),
    }),

    quizzes: Yup.array()
      .of(quizzesSchema)
      .required("Must have Quiz")
      .min(3, "Minimum of 3 quizes are required")
      .max(3, "Maxium limit of quizes are 3"),

    impacts: Yup.array()
      .of(impactOnOtherSchema)
      .required("Must have Impact on Others")
      .min(1, "Minimum of 1 Impact on Others required"),

    trends: Yup.array()
      .of(careerTrendsSchema)
      .required("Must have Days In Life")
      .min(2, "Minimum of 2 US Trends required"),

    did_you_knows: Yup.array()
      .of(didYouKnowSchema)
      .required("Must have Career Trends")
      .min(2, "Minimum of 2 Career Trends required"),

    days_in_life: Yup.array()
      .of(daysInLifeSchema)
      .required("Must have Days In Life")
      .min(6, "Minimum of 6 Days In Life required"),

    expert_voices: Yup.array()
      .of(exportVoiceSchema)
      .required("Must have Cover Video and It's thumbnail")
      .min(1, "Minimum of 1 Export Voice is required"),

    // educations: Yup.array().of(educationSchema),
  });

  const [validationSchema, setValidationSchema] = useState(
    draftValidationSchema
  );

  const handleFormSubmit = (
    values: CareerInfo,
    { setSubmitting, setFieldValue }: FormikHelpers<any>
  ) => {
    setSubmitting(false);
    handleSubmit(values);
  };

  return (
    <Formik
      initialValues={careerInfo}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={false}
      onSubmit={handleFormSubmit}
    >
      {({
        setFieldValue,
        values,
        errors,
        setStatus,
        setSubmitting,
        validateForm,
        setErrors,
        submitForm,
      }) => (
        <>
          <Form>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="text-black text-center bg-horizontalRow gap-3 mb-4 w-[80px] h-[35px] rounded-full text-sm font-normal leading-5"
            >
              <div className="flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="12"
                  viewBox="0 0 12 8"
                  fill="none"
                >
                  <path
                    d="M11 4.5C11.2761 4.5 11.5 4.27614 11.5 4C11.5 3.72386 11.2761 3.5 11 3.5L11 4.5ZM0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659727 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z"
                    fill="black"
                  />
                </svg>
                <span className="pl-2 font-semibold">Back</span>
              </div>
            </button>
            <div className="flex flex-col items-start justify-between gap-2 lg:items-end lg:flex-row ">
              <div className="flex flex-col order-2 lg:order-2">
                <span className="text-sm font-normal leading-5">
                  Update career information below.
                </span>
                <span className="pb-4 section-title pt-[8px]">Career Info</span>
              </div>
              {enableButtons && <div className="flex items-center order-1 gap-2 lg:order-2">
                {careerInfo.status === "draft" && (
                  <>
                    <button
                      type="button"
                      disabled={isLoading}
                      name="save"
                      onClick={async (e) => {
                        e.preventDefault();
                        await setValidationSchema(draftValidationSchema);
                        setErrors({}); // Reset any previous validation errors
                        setFieldValue("status", Status.Draft);
                        validateForm().then((errors) => {
                          if (Object.keys(errors).length !== 0) {
                            displayError("Please fix issues with form first.");
                          }
                          submitForm();
                        });
                      }}
                      className="text-center bg-transparent border border-headerBlue text-headerBlue gap-3  w-[98px] lg:w-[121px] h-[40px] lg:h-[32px] rounded-full text-sm font-normal leading-5"
                    >
                      {isLoading && careerInfo.status === "draft" ? (
                        <Loading />
                      ) : (
                        "Save"
                      )}
                    </button>
                    <button
                      type="button"
                      disabled={isLoading}
                      name="saveandexit"
                      onClick={async (e) => {
                        e.preventDefault();
                        await setValidationSchema(draftValidationSchema);
                        setErrors({}); // Reset any previous validation errors
                        setFieldValue("status", Status.Draft);
                        validateForm().then((errors) => {
                          if (Object.keys(errors).length !== 0) {
                            displayError("Please fix issues with form first.");
                          }
                          submitForm();
                          navigate(-1)
                        });
                      }}
                      className="text-center bg-transparent border border-headerBlue text-headerBlue gap-3  w-[98px] lg:w-[121px] h-[40px] lg:h-[32px] rounded-full text-sm font-normal leading-5"
                    >
                      {isLoading && careerInfo.status === "draft" ? (
                        <Loading />
                      ) : (
                        "Save & Exit"
                      )}
                    </button>
                  </>
                )}
                {careerInfo.status === "archive" && (
                  <>
                    <button
                      type="button"
                      disabled={isLoading}
                      name="save"
                      onClick={async (e) => {
                        e.preventDefault();
                        await setValidationSchema(publishValidationSchema);
                        setErrors({}); // Reset any previous validation errors
                        setFieldValue("status", Status.Archive);
                        validateForm().then((errors) => {
                          if (Object.keys(errors).length !== 0) {
                            displayError("Please fix issues with form first.");
                          }
                          submitForm();
                        });
                      }}
                      className="text-center bg-transparent border border-headerBlue text-headerBlue gap-3  w-[98px] lg:w-[121px] h-[40px] lg:h-[32px] rounded-full text-sm font-normal leading-5"
                    >
                      {isLoading && careerInfo.status === "archive" ? (
                        <Loading />
                      ) : (
                        "Save Archive"
                      )}
                    </button>
                    <button
                      type="button"
                      disabled={isLoading}
                      name="saveandexit"
                      onClick={async (e) => {
                        e.preventDefault();
                        await setValidationSchema(publishValidationSchema);
                        setErrors({}); // Reset any previous validation errors
                        setFieldValue("status", Status.Archive);
                        validateForm().then((errors) => {
                          if (Object.keys(errors).length !== 0) {
                            displayError("Please fix issues with form first.");
                          }
                          submitForm();
                          navigate(-1)
                        });
                      }}
                      className="text-center bg-transparent border border-headerBlue text-headerBlue gap-3  w-[98px] lg:w-[121px] h-[40px] lg:h-[32px] rounded-full text-sm font-normal leading-5"
                    >
                      {isLoading && careerInfo.status === "archive" ? (
                        <Loading />
                      ) : (
                        "Save & Exit"
                      )}
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  name="published"
                  onClick={async (e) => {
                    e.preventDefault();
                    await setValidationSchema(publishValidationSchema);
                    setErrors({}); // Reset any previous validation errors
                    await setFieldValue("status", Status.Publish);
                    validateForm().then((errors) => {
                      if (Object.keys(errors).length !== 0) {
                        displayError("Please fix issues with form first.");
                      }
                      submitForm();
                    });
                  }}
                  className="text-white text-center bg-green gap-3  w-[98px] lg:w-[121px] h-[40px] lg:h-[32px] rounded-full text-sm font-normal leading-5"
                >
                  {isLoading && careerInfo.status === Status.Publish ? (
                    <Loading text="Publishing..." />
                  ) : (
                    "Publish"
                  )}
                </button>
              </div>}
            </div>
            <div className="gap-3">
              <div>
                <ImagePicker
                  id="icon_url"
                  key={values.icon_url}
                  name="icon_url"
                  className="w-[70px] h-[72px] relative"
                  svgPenClassName="absolute bottom-[0px] right-[-10px] "
                  svgWidth={27}
                  svgHeight={27}
                  order={1}
                  mediaId={values.icon.id as string}
                  media={values.icon_url}
                  enableAutoUpload={true}
                  onFileUploaded={(e: any) => {
                    if (careerInfo.icon.id) {
                      setFieldValue(`icon.is_changed`, true);
                    }
                    setFieldValue("icon.id", e.id);
                    setFieldValue("icon_url", e.temp_url || e.file);
                  }}
                />
              </div>
            </div>
            <div className="career-section career-info !pt-0">
              <div className="max-w-[462px] pt-[11px]">
                <GlimpseInputBox
                  id="name"
                  name="name"
                  label="Career Name"
                  type="input"
                  placeholder={"Career Name"}
                  maxLength={50}
                />
              </div>
              <div className="max-w-[462px]">
                <GlimpseInputBox
                  id="superpower"
                  name="superpower"
                  label="Superpower Name"
                  type="input"
                  placeholder={"Superpower Name"}
                  maxLength={50}
                />
              </div>
              <div className="max-w-[462px]">
                <GlimpseTextAreaBox
                  id="description"
                  name="description"
                  label="Career Description"
                  placeholder="Career Description..."
                  maxLength={130}
                  showCount={true}
                />
              </div>
            </div>
            <div className="career-section contribution">
              <span className="section-title">Contribution</span>
              <div>
                <ImagePicker
                  id={"contribution_url"}
                  key={values.contribution_url}
                  name="contribution_url"
                  className="w-[143px] h-[140px] relative"
                  svgImgClassName="m-auto h-[100%]"
                  svgPenClassName="absolute bottom-[0px] right-[-17px]"
                  svgWidth={43}
                  svgHeight={43}
                  mediaId={values.contribution.id as string}
                  enableAutoUpload={true}
                  order={1}
                  media={values.contribution_url}
                  onFileUploaded={(e: any) => {
                    if (careerInfo.contribution.id) {
                      setFieldValue(`contribution.is_changed`, true);
                    }
                    setFieldValue("contribution.id", e.id);
                    setFieldValue("contribution_url", e.temp_url || e.file);
                  }}
                />
              </div>
            </div>
            <div className="career-section expert-voices">
              <span className="section-title">Expert Voices</span>
              {errors.expert_voices &&
                typeof errors.expert_voices === "string" && (
                  <ErrorMessage name={"expert_voices"} component={InputError} />
                )}
              <FieldArray
                name="expert_voices"
                render={({ push, remove, }) => (
                  <div className="flex flex-col gap-4 md:flex-row ">
                    {values.expert_voices.map((item, index) => {
                      return (
                        <div key={index}>
                          <div
                            key={index}
                            className="w-[342px] h-[271px]  md:w-[245px] md:h-[189px] relative border border-input_outline p-5 flex gap-5 rounded-xl"
                          >
                            {item.order > 1 && (
                              <span
                                className="absolute flex items-end justify-end top-1 -right-2"
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                <svg
                                  width="22"
                                  height="22"
                                  viewBox="0 0 22 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18.5 18.5C22.6421 14.3579 22.6421 7.64214 18.5 3.5C14.3579 -0.642135 7.64214 -0.642135 3.5 3.5C-0.642135 7.64214 -0.642135 14.3579 3.5 18.5C7.64214 22.6421 14.3579 22.6421 18.5 18.5Z"
                                    fill="#404040"
                                  />
                                  <path
                                    d="M6.17206 15.8279C5.94265 15.5985 5.94265 15.2265 6.17206 14.9956L14.9956 6.17206C15.225 5.94265 15.5985 5.94265 15.8279 6.17206C16.0574 6.40147 16.0574 6.775 15.8279 7.00441L7.00441 15.8279C6.775 16.0574 6.40294 16.0574 6.17206 15.8279Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M14.9956 15.8279L6.17206 7.00441C5.94265 6.775 5.94265 6.40147 6.17206 6.17206C6.40147 5.94265 6.775 5.94265 7.00441 6.17206L15.8279 14.9956C16.0574 15.225 16.0574 15.5971 15.8279 15.8279C15.5985 16.0574 15.225 16.0574 14.9956 15.8279Z"
                                    fill="white"
                                  />
                                </svg>
                              </span>
                            )}
                            <div>
                              <VideoPicker
                                id={`expert_voices.${index}.voice_url`}
                                name={`expert_voices.${index}.voice_url`}
                                className={`min-w-[134px] md:min-w-[94px] h-full relative rounded-lg ${item.order === 1 &&
                                  " border-2 border-headerBlue"
                                  } `}
                                svgImgClassName="m-auto h-[100%]"
                                svgPenClassName="absolute bottom-[0px] right-[-16px]"
                                svgWidth={43}
                                svgHeight={43}
                                mediaId={item.voice.id as string}
                                order={item.order}
                                media={item.voice_url}
                                enableAutoUpload={true}
                                onFileUploaded={(e: any, order: number) => {
                                  if (
                                    values.expert_voices[index].voice.id
                                  ) {
                                    setFieldValue(
                                      `expert_voices.${index}.voice.is_changed`,
                                      true
                                    );
                                  }
                                  setFieldValue(
                                    `expert_voices.${index}.voice.id`,
                                    e.id
                                  );
                                  setFieldValue(
                                    `expert_voices.${index}.voice_url`,
                                    e.temp_url || e.file
                                  );
                                }}
                              />
                              <ErrorMessage
                                id={`expert_voices.${index}.voice_url`}
                                name={`expert_voices.${index}.voice_url`}
                                component={InputError}
                              />
                              {item.order === 1 && (
                                <div className="h-[16px] font-[400] text-[12px] leading-[16px] text-headerBlue font-sans text-center">
                                  Cover Video
                                </div>
                              )}
                            </div>
                            <div>
                              <ImagePicker
                                id={`expert_voices.${index}.voice_thumbnail`}
                                name={`expert_voices.${index}.voice_thumbnail`}
                                className={`min-w-[134px] md:min-w-[94px] h-full relative rounded-lg ${item.order === 1 &&
                                  " border-2 border-headerBlue"
                                  } `}
                                svgImgClassName="m-auto h-[100%]"
                                svgPenClassName="absolute bottom-[0px] right-[-17px]"
                                svgWidth={43}
                                svgHeight={43}
                                order={item.order}
                                mediaId={item.voice.id as string}
                                enableAutoUpload={true}
                                media={item.voice_thumbnail}
                                image_type="career_video"
                                onFileUploaded={(e: any) => {
                                  if (
                                    values.expert_voices[index].voice?.id
                                  ) {
                                    setFieldValue(
                                      `expert_voices.${index}.voice.is_thumbnail_changed`,
                                      true
                                    );
                                  }
                                  // if (!careerInfo.expert_voices[index].voice) {
                                  //   setFieldValue(
                                  //     `expert_voices.${index}.voice`,
                                  //     {
                                  //       id: e.id,
                                  //       is_changed: false,
                                  //       is_thumbnail_changed: false
                                  //     }
                                  //   );
                                  // }
                                  // else {
                                  //   setFieldValue(
                                  //     `expert_voices.${index}.voice.id`,
                                  //     e.id
                                  //   );
                                  // }
                                  setFieldValue(
                                    `expert_voices.${index}.voice.id`,
                                    e.id
                                  );
                                  setFieldValue(
                                    `expert_voices.${index}.voice_thumbnail`,
                                    e.temp_thumbnail_url || e.thumbnail
                                  );
                                }}
                              />
                              {item.order === 1 && (
                                <div className="h-[16px] font-[400] text-[12px] leading-[16px] text-headerBlue font-sans text-center">
                                  Cover Thumbnail
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <AddNewSection
                      className="w-[130px] justify-center ml-3 pt-[19px] md:pt-0"
                      label="Add Section"
                      subLabel="Required Sections: 1"
                      isEnable={true}
                      clicked={(e: React.MouseEvent<HTMLDivElement>) => {
                        if (values.expert_voices.length < 3) {
                          push({
                            order: values.expert_voices.length + 1,
                            title: "",
                            voice: {
                              id: null,
                              is_changed: false,
                              is_thumbnail_changed: false
                            },
                            voice_thumbnail: "",
                            voice_url: "",
                          });
                        } else {
                          displayWarning(
                            "You can not add more than 3 items for expert voice."
                          );
                        }
                      }}
                      key="add_new_selection"
                    />
                  </div>
                )}
              />
            </div>
            <div className="career-section day-in-life">
              <span className="section-title">A Day in the Life </span>
              {errors.days_in_life &&
                typeof errors.days_in_life === "string" && (
                  <ErrorMessage name={"days_in_life"} component={InputError} />
                )}
              <FieldArray
                name="days_in_life"
                render={({ push }) => (
                  <>
                    {values.days_in_life.map((item, index) => {
                      return (
                        <div className="mt-3" key={index}>
                          {item.order > 1 && (
                            <div className="pt-3 border-t border-horizontalRow w-[300px]"></div>
                          )}
                          <div>
                            <ImagePicker
                             key={item.image_url}
                              id={`days_in_life.${index}.image_url`}
                              name={`days_in_life.${index}.image_url`}
                              className="w-[70px] h-[72px] relative"
                              svgPenClassName="absolute bottom-[0px] right-[-10px] "
                              svgWidth={27}
                              svgHeight={27}
                              order={1}
                              mediaId={item.image.id as string}
                              media={item.image_url}
                              enableAutoUpload={true}
                              onFileUploaded={(e: any) => {
                                if (values.days_in_life[index].image.id) {
                                  setFieldValue(
                                    `days_in_life.${index}.image.is_changed`,
                                    true
                                  );
                                }
                                setFieldValue(
                                  `days_in_life.${index}.image.id`,
                                  e.id
                                );
                                setFieldValue(
                                  `days_in_life.${index}.image_url`,
                                  e.temp_url || e.file
                                );
                              }}
                            />
                          </div>
                          <div className="max-w-[462px] pt-[11px]">
                            <GlimpseInputBox
                              id={`days_in_life.${index}.title`}
                              name={`days_in_life.${index}.title`}
                              label="Subtitle"
                              type="input"
                              placeholder={"Subtitle"}
                              showCount={true}
                              maxLength={25}
                            />
                          </div>
                          <div className="max-w-[462px] pt-[11px]">
                            <GlimpseTextAreaBox
                              id={`days_in_life.${index}.description`}
                              name={`days_in_life.${index}.description`}
                              label="Section Copy"
                              placeholder="Section Copy..."
                              maxLength={110}
                              showCount={true}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <AddNewSection
                      className="w-[130px]"
                      label="Add Section"
                      subLabel="Required Sections: 6"
                      isEnable={values.days_in_life.length < 6}
                      clicked={(e: React.MouseEvent<HTMLDivElement>) => {
                        if (values.days_in_life.length < 6) {
                          push({
                            order: values.days_in_life.length + 1,
                            description: "",
                            image: { id: "", is_changed: false },
                            image_url: "",
                            title: "",
                          });
                        } else {
                          displayWarning(
                            "You can not add more than 6 A Day in the Life."
                          );
                        }
                      }}
                      key="add_new_selection"
                    />
                  </>
                )}
              />
            </div>
            <div className="career-section pros-cons">
              <span className="section-title">Pros & Cons </span>
              {errors.pros_cons?.pros &&
                typeof errors.pros_cons?.pros === "string" && (
                  <ErrorMessage name={"pros"} component={InputError} />
                )}
              <div className="block lg:flex lg:flex-row max-w-[619px] justify-between">
                <FieldArray
                  name="pros_cons.pros"
                  render={({ move, swap, push, insert, unshift, pop }) => (
                    <>
                      <div className="block lg:flex lg:flex-row max-w-[619px] justify-between">
                        <div>
                          <div className="min-w-[300px]">
                            <label>Common Advantages</label>
                            {values.pros_cons.pros
                              .filter((x) => x.type === ProsCons.Pros)
                              .map((item, index) => {
                                return (
                                  <GlimpseInputBox
                                    key={index}
                                    id={`pros_cons.pros.${index}.title`}
                                    name={`pros_cons.pros.${index}.title`}
                                    label=""
                                    type="input"
                                    placeholder={`Pro #${index + 1}`}
                                    showCount={true}
                                    maxLength={35}
                                  />
                                );
                              })}
                          </div>
                          <AddNewSection
                            className="w-[140px] pt-[15px]"
                            label="Add Advantage"
                            isEnable={
                              values.pros_cons.pros.filter(
                                (x) => x.type === ProsCons.Pros
                              ).length < 5
                            }
                            subLabel="Required Lines: 5"
                            clicked={(e: React.MouseEvent<HTMLDivElement>) => {
                              if (
                                values.pros_cons.pros.filter(
                                  (x) => x.type === ProsCons.Pros
                                ).length < 5
                              ) {
                                push({
                                  title: "",
                                  type: ProsCons.Pros,
                                  order: values.pros_cons.pros.length + 1,
                                });
                              } else {
                                displayWarning(
                                  "You can not add more than 5 Common Advantages."
                                );
                              }
                            }}
                            key="add_new_selection"
                          />
                        </div>
                      </div>
                    </>
                  )}
                />
                <FieldArray
                  name="pros_cons.cons"
                  render={({ move, swap, push, insert, unshift, pop }) => (
                    <>
                      <div className="block lg:flex lg:flex-row max-w-[619px] justify-between">
                        <div>
                          <div className="min-w-[300px] pt-[15px] lg:pt-0">
                            <label>Potential Drawbacks</label>
                            {values.pros_cons.cons
                              .filter((x) => x.type === ProsCons.Cons)
                              .map((item, index) => {
                                return (
                                  <GlimpseInputBox
                                    key={index}
                                    id={`pros_cons.cons.${index}.title`}
                                    name={`pros_cons.cons.${index}.title`}
                                    label=""
                                    type="input"
                                    placeholder={`Con #${index + 1}`}
                                    showCount={true}
                                    maxLength={35}
                                  />
                                );
                              })}
                          </div>
                          <AddNewSection
                            className="w-[140px] pt-[15px]"
                            label="Add Drawback"
                            isEnable={
                              values.pros_cons.cons.filter(
                                (x) => x.type === ProsCons.Cons
                              ).length < 5
                            }
                            subLabel="Required Lines: 5"
                            clicked={(e: React.MouseEvent<HTMLDivElement>) => {
                              if (
                                values.pros_cons.cons.filter(
                                  (x) => x.type === ProsCons.Cons
                                ).length < 5
                              ) {
                                push({
                                  title: "",
                                  type: ProsCons.Cons,
                                  order: values.pros_cons.cons.length + 1,
                                });
                              } else {
                                displayWarning(
                                  "You can not add more than 5 Potential Drawbacks."
                                );
                              }
                            }}
                            key="add_new_selection"
                          />
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
            <div className="career-section did-you-know">
              <span className="section-title">Did You Know? </span>
              {errors.did_you_knows &&
                typeof errors.did_you_knows === "string" && (
                  <ErrorMessage name={"did_you_knows"} component={InputError} />
                )}
              <FieldArray
                name="did_you_knows"
                render={({ move, swap, push, insert, unshift, pop }) => (
                  <>
                    {values.did_you_knows.map((item, index) => {
                      return (
                        <div className="mt-3" key={index}>
                          {item.order > 1 && (
                            <div className="pt-3 border-t border-horizontalRow w-[300px]"></div>
                          )}
                          <div>
                            <ImagePicker
                              id={`did_you_knows.${index}.icon_url`}
                              name={`did_you_knows.${index}.icon_url`}
                              className="w-[70px] h-[72px] relative"
                              svgPenClassName="absolute bottom-[0px] right-[-10px] "
                              svgWidth={27}
                              svgHeight={27}
                              order={1}
                              mediaId={item.icon.id as string}
                              media={item.icon_url}
                              enableAutoUpload={true}
                              onFileUploaded={(e: any) => {
                                if (values.did_you_knows[index].icon.id) {
                                  setFieldValue(
                                    `did_you_knows.${index}.icon.is_changed`,
                                    true
                                  );
                                }
                                setFieldValue(
                                  `did_you_knows.${index}.icon.id`,
                                  e.id
                                );
                                setFieldValue(
                                  `did_you_knows.${index}.icon_url`,
                                  e.temp_url || e.file
                                );
                              }}
                            />
                          </div>
                          <div className="max-w-[462px] pt-[11px]">
                            <GlimpseInputBox
                              id={`did_you_knows.${index}.title`}
                              name={`did_you_knows.${index}.title`}
                              label="Section Copy"
                              type="input"
                              placeholder={"Section Copy"}
                              showCount={true}
                              maxLength={100}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <AddNewSection
                      className="w-[130px]"
                      label="Add Section"
                      isEnable={values.did_you_knows.length < 4}
                      subLabel="Required Sections: 4"
                      clicked={(e: React.MouseEvent<HTMLDivElement>) => {
                        if (values.did_you_knows.length < 4) {
                          push({
                            order: values.did_you_knows.length + 1,
                            title: "",
                            icon: { id: "", is_changed: false },
                            icon_url: "",
                          });
                        } else {
                          displayWarning(
                            "You can not add more than 4 Did You Know."
                          );
                        }
                      }}
                      key="add_new_selection"
                    />
                  </>
                )}
              />
            </div>
            <div className="career-section education">
              <span className="section-title">Education</span>
              {errors.educations && typeof errors.educations === "string" && (
                <ErrorMessage name={"educations"} component={InputError} />
              )}
              <div className="max-w-[309px] ">
                <div className="">
                  <FieldArray
                    name="educations"
                    render={({ move, swap, push, insert, unshift, pop }) => (
                      <>
                        {values.educations
                          .sort((a, b) => a.order - b.order)
                          .map((item, index) => (
                            <div
                              key={index}
                              className=" leading-6 py-[19px] first:py-0 border-b last:border-b-0 border-horizontalRow last:pb-0"
                            >
                              {item.order !== 1 ? (
                                <>
                                  <GlimpseCheckbox
                                    checked={item.is_enabled}
                                    label={
                                      item.order === 5
                                        ? "Post-Graduate Degree"
                                        : (item.title as string)
                                    }
                                    onChange={(ev: any) => {
                                      setFieldValue(
                                        `educations[${index}].is_enabled`,
                                        ev.target.checked
                                      );
                                      if (!ev.target.checked) {
                                        setFieldValue(
                                          `educations[${index}].type`,
                                          null
                                        );
                                      }
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <label className="relative items-center cursor-pointer">
                                    <div className="font-medium text-md">
                                      {item.title}
                                    </div>
                                  </label>
                                </>
                              )}

                              {(item.is_enabled || item.order === 1) && (
                                <>
                                  {item.order === 5 ? (
                                    <>
                                      <GlimpseInputBox
                                        id={`educations[${index}].duration`}
                                        name={`educations[${index}].duration`}
                                        label="Duration In Years"
                                        type="input"
                                        placeholder={"Duration In Years"}
                                        maxLength={50}
                                      />
                                      <div className=" max-w-[343px] pt-[11px] mb-[10px]">
                                        <GlimpseInputBox
                                          id={`educations[${index}].title`}
                                          name={`educations[${index}].title`}
                                          label="Degree Name"
                                          type="input"
                                          placeholder={"Degree Name"}
                                          maxLength={50}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <div className="text-sm font-medium">
                                      {item.duration} Years
                                    </div>
                                  )}

                                  <div
                                    role="group"
                                    aria-labelledby="my-radio-group"
                                    className="flex gap-3"
                                  >
                                    <label>
                                      <Field
                                        type="radio"
                                        name={`educations[${index}].type`}
                                        title="Required"
                                        value="required"
                                        className="bg-white active:bg-headerBlue checked:bg-headerBlue checked:focus:bg-headerBlue focus:ring-0"
                                      />
                                      <span className="ml-3 text-md">
                                        Required
                                      </span>
                                    </label>
                                    <label>
                                      <Field
                                        type="radio"
                                        name={`educations[${index}].type`}
                                        title="Recommended"
                                        value="recommended"
                                        className="bg-white active:bg-headerBlue checked:bg-headerBlue checked:focus:bg-headerBlue focus:ring-0 checked:hover:bg-headerBlue hover:bg-headerBlue"
                                      />
                                      <span className="ml-3 text-md">
                                        Recommended
                                      </span>
                                    </label>
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                      </>
                    )}
                  />
                  <div className=" leading-6 py-[19px] border-b first:border-t last:border-b-0 last:pb-0">
                    <GlimpseCheckbox
                      checked={values.certificates.is_enabled}
                      label="Certification"
                      onChange={(ev) => {
                        setFieldValue(
                          "certificates.is_enabled",
                          ev.target.checked
                        );
                        if (!ev.target.checked) {
                          setFieldValue("certificates.certificate_list", [
                            {
                              title: "",
                              duration: 0,
                              type: null,
                              order: 1,
                            },
                          ]);
                        }
                      }}
                    />
                    <FieldArray
                      name="certificates.certificate_list"
                      render={({
                        move,
                        swap,
                        push,
                        remove,
                        insert,
                        unshift,
                        pop,
                      }) => (
                        <>
                          {values.certificates.is_enabled &&
                            values.certificates.certificate_list?.map(
                              (item, index) => {
                                return (
                                  <div className="pb-3 " key={index}>
                                    {item.order > 1 && (
                                      <div className="pt-3 border-t border-horizontalRow w-[300px]"></div>
                                    )}
                                    <div className="max-w-[343px] pt-[11px] relative">
                                      {item.order > 1 && (
                                        <span
                                          className="absolute right-0 flex items-end justify-end top-1"
                                          onClick={() => {
                                            remove(index);
                                          }}
                                        >
                                          <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M18.5 18.5C22.6421 14.3579 22.6421 7.64214 18.5 3.5C14.3579 -0.642135 7.64214 -0.642135 3.5 3.5C-0.642135 7.64214 -0.642135 14.3579 3.5 18.5C7.64214 22.6421 14.3579 22.6421 18.5 18.5Z"
                                              fill="#404040"
                                            />
                                            <path
                                              d="M6.17206 15.8279C5.94265 15.5985 5.94265 15.2265 6.17206 14.9956L14.9956 6.17206C15.225 5.94265 15.5985 5.94265 15.8279 6.17206C16.0574 6.40147 16.0574 6.775 15.8279 7.00441L7.00441 15.8279C6.775 16.0574 6.40294 16.0574 6.17206 15.8279Z"
                                              fill="white"
                                            />
                                            <path
                                              d="M14.9956 15.8279L6.17206 7.00441C5.94265 6.775 5.94265 6.40147 6.17206 6.17206C6.40147 5.94265 6.775 5.94265 7.00441 6.17206L15.8279 14.9956C16.0574 15.225 16.0574 15.5971 15.8279 15.8279C15.5985 16.0574 15.225 16.0574 14.9956 15.8279Z"
                                              fill="white"
                                            />
                                          </svg>
                                        </span>
                                      )}
                                      <GlimpseInputBox
                                        id={`certificates.certificate_list.${index}.duration`}
                                        name={`certificates.certificate_list.${index}.duration`}
                                        label="Duration In Years"
                                        type="input"
                                        placeholder={"Duration In Years"}
                                        maxLength={50}
                                      />
                                    </div>
                                    <div className=" max-w-[343px] pt-[11px]">
                                      <GlimpseInputBox
                                        id={`certificates.certificate_list.${index}.title`}
                                        name={`certificates.certificate_list.${index}.title`}
                                        label="Certificate Name"
                                        type="input"
                                        placeholder={"Certificate Name"}
                                        maxLength={50}
                                      />
                                    </div>
                                    <div
                                      role="group"
                                      aria-labelledby="my-radio-group"
                                      className="flex gap-3"
                                    >
                                      <label>
                                        <Field
                                          type="radio"
                                          name={`certificates.certificate_list.${index}.type`}
                                          title="Required"
                                          value="required"
                                          className="bg-white active:bg-headerBlue checked:bg-headerBlue checked:focus:bg-headerBlue focus:ring-0"
                                        />
                                        <span className="ml-3 text-md">
                                          Required
                                        </span>
                                      </label>
                                      <label>
                                        <Field
                                          type="radio"
                                          name={`certificates.certificate_list.${index}.type`}
                                          title="Recommended"
                                          value="recommended"
                                          className="bg-white active:bg-headerBlue checked:bg-headerBlue checked:focus:bg-headerBlue focus:ring-0 checked:hover:bg-headerBlue hover:bg-headerBlue"
                                        />
                                        <span className="ml-3 text-md">
                                          Recommended
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          {values.certificates.is_enabled && (
                            <AddNewSection
                              className="w-[130px] mt-3"
                              label="Add Section"
                              isEnable={true}
                              subLabel=""
                              clicked={(
                                e: React.MouseEvent<HTMLDivElement>
                              ) => {
                                push({
                                  title: "",
                                  duration: 0,
                                  type: null,
                                  order:
                                    values.certificates.certificate_list
                                      .length + 1,
                                });
                              }}
                              key="add_new_selection"
                            />
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="career-section salary">
              <span className="section-title">Salary</span>
              <div className="block xl:flex xl:flex-row max-w-[938px] justify-between ">
                <div className="lg:min-w-[300px] max-w-[462px]">
                  <label
                    htmlFor={"Sartring"}
                    className="block text-sm font-[500] leading-4 text-gray-900"
                  >
                    Starting
                  </label>
                  <CurrencyInput
                    id="salaries.starting"
                    name="salaries.starting"
                    placeholder="Low/Starting"
                    decimalsLimit={0}
                    prefix={"$ "}
                    value={values.salaries.starting as number}
                    className="block w-full h-[40px]  p-3 mt-1 text-gray-900 border shadow-sm rounded-xl border-input_outline placeholder:text-headerBlack sm:text-sm sm:leading-6 text-headerBlack focus:ring-0 focus:border-input_outline focus:outline-0"
                    onValueChange={(value, name, values) => {
                      setFieldValue(`salaries.starting`, value);
                    }}
                  />
                </div>
                <div className="lg:min-w-[300px] max-w-[462px] pt-[15px] xl:pt-0">
                  <label
                    htmlFor={"Sartring"}
                    className="block text-sm font-[500] leading-4 text-gray-900"
                  >
                    Median
                  </label>
                  <CurrencyInput
                    id="salaries.median"
                    name="salaries.median"
                    placeholder="Median"
                    decimalsLimit={0}
                    prefix={"$ "}
                    value={values.salaries.median as number}
                    className="block w-full h-[40px]  p-3 mt-1 text-gray-900 border shadow-sm rounded-xl border-input_outline placeholder:text-headerBlack sm:text-sm sm:leading-6 text-headerBlack focus:ring-0 focus:border-input_outline focus:outline-0"
                    onValueChange={(value, name, values) => {
                      setFieldValue(`salaries.median`, value);
                    }}
                  />
                  {/* <GlimpseInputBox
                    id="salaries.median"
                    name="salaries.median"
                    placeholder="Median"
                    label="Median"
                    as="input"
                    type="number"
                    maxLength={50}
                    isSalary={true}
                  /> */}
                </div>
                <div className="lg:min-w-[300px] max-w-[462px] pt-[15px] xl:pt-0">
                  <label
                    htmlFor={"Sartring"}
                    className="block text-sm font-[500] leading-4 text-gray-900"
                  >
                    High/Advanced
                  </label>
                  <CurrencyInput
                    id="salaries.advance"
                    name="salaries.advance"
                    placeholder="High/Advanced"
                    decimalsLimit={0}
                    prefix={"$ "}
                    value={values.salaries.advance as number}
                    className="block w-full h-[40px]  p-3 mt-1 text-gray-900 border shadow-sm rounded-xl border-input_outline placeholder:text-headerBlack sm:text-sm sm:leading-6 text-headerBlack focus:ring-0 focus:border-input_outline focus:outline-0"
                    onValueChange={(value, name, values) => {
                      setFieldValue(`salaries.advance`, value);
                    }}
                  />
                  {/* <GlimpseInputBox
                    id="salaries.advance"
                    name="salaries.advance"
                    placeholder="High/Advanced"
                    label="High/Advanced"
                    as="input"
                    type="number"
                    maxLength={50}
                    isSalary={true}
                  /> */}
                </div>
              </div>
            </div>
            <div className="career-section career-trends">
              <span className="section-title">Career Trends in the U.S.</span>
              {errors.trends && typeof errors.trends === "string" && (
                <ErrorMessage name={"trends"} component={InputError} />
              )}
              <FieldArray
                name="trends"
                render={({ move, swap, push, insert, unshift, pop }) => (
                  <>
                    {values.trends.map((item, index) => {
                      return (
                        <div className="mt-3" key={index}>
                          <div className="max-w-[462px] pt-[11px]">
                            <GlimpseInputBox
                              id={`trends.${index}.title`}
                              name={`trends.${index}.title`}
                              label="Title"
                              type="input"
                              placeholder={"Title"}
                              showCount={true}
                              maxLength={11}
                            />
                          </div>
                          <div className="max-w-[462px] pt-[11px]">
                            <GlimpseTextAreaBox
                              id={`trends.${index}.description`}
                              name={`trends.${index}.description`}
                              label="Description"
                              placeholder={"Description"}
                              maxLength={135}
                              showCount={true}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <AddNewSection
                      className="w-[130px]"
                      label="Add Section"
                      isEnable={values.trends.length < 2}
                      subLabel="Required Sections: 2"
                      clicked={(e: React.MouseEvent<HTMLDivElement>) => {
                        if (values.trends.length < 2) {
                          push({
                            description: "",
                            title: "",
                          });
                        } else {
                          displayWarning(
                            "You can not add more than 2 Career Trends."
                          );
                        }
                      }}
                      key="add_new_selection"
                    />
                  </>
                )}
              />
            </div>
            <div className="career-section impact-on-others">
              <span className="section-title">Impact on Others</span>
              {errors.impacts && typeof errors.impacts === "string" && (
                <ErrorMessage name={"impacts"} component={InputError} />
              )}
              <FieldArray
                name="impacts"
                render={({ move, swap, push, insert, unshift, pop }) => (
                  <>
                    {values.impacts.map((item, index) => {
                      return (
                        <div className="mt-3" key={index}>
                          <div className="max-w-[462px] pt-[11px]">
                            <GlimpseTextAreaBox
                              id={`impacts.${index}.description`}
                              name={`impacts.${index}.description`}
                              label="Description"
                              placeholder={"Description"}
                              showCount={true}
                              maxLength={250}
                            />
                          </div>
                          <div className="max-w-[462px] pt-[11px]">
                            <GlimpseInputBox
                              id={`impacts.${index}.author`}
                              name={`impacts.${index}.author`}
                              label="Author"
                              type="input"
                              placeholder={"Author"}
                              maxLength={50}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <AddNewSection
                      className="w-[130px]"
                      label="Add Section"
                      isEnable={values.impacts.length < 2}
                      subLabel="Required Sections: 1"
                      clicked={(e: React.MouseEvent<HTMLDivElement>) => {
                        if (values.impacts.length < 2) {
                          push({
                            description: "",
                            title: "",
                          });
                        } else {
                          displayWarning(
                            "You can not add more than 2 Impact on Others."
                          );
                        }
                      }}
                      key="add_new_selection"
                    />
                  </>
                )}
              />
            </div>
            <div className="career-section quiz">
              <span className="section-title">Quiz</span>
              {errors.quizzes && typeof errors.quizzes === "string" && (
                <ErrorMessage name={"quizzes"} component={InputError} />
              )}
              <FieldArray
                name="quizzes"
                render={({ move, swap, push, insert, unshift, pop }) => (
                  <>
                    {values.quizzes.map((item, index) => {
                      return (
                        <div key={index} className="max-w-[462px] pt-[11px]">
                          <div className="max-w-[462px] pt-[11px]">
                            <GlimpseInputBox
                              id={`quizzes.${index}.question`}
                              name={`quizzes.${index}.question`}
                              label={`Question ${index + 1}`}
                              type="input"
                              placeholder={`Question ${index + 1}`}
                              showCount={true}
                              maxLength={50}
                            />
                          </div>
                          <FieldArray
                            name={`quizzes[${index}].answers`}
                            render={({
                              move,
                              swap,
                              push,
                              insert,
                              unshift,
                              pop,
                            }) => (
                              <>
                                {values.quizzes[index].answers.map(
                                  (answer: IAnswer, answerIndex: number) => {
                                    return (
                                      <div
                                        className="inline-block w-full mt-1"
                                        key={answerIndex}
                                      >
                                        <label
                                          htmlFor="answer-1"
                                          className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                          Answer {answerIndex + 1}
                                        </label>
                                        <div className="flex items-center px-2 border shadow-sm border-input_outline rounded-xl h-[40px]">
                                          <input
                                            type="radio"
                                            id={`quizzes.${index}.answers.${answerIndex}.is_correct`}
                                            name={`quizzes.${index}.answers.${answerIndex}.is_correct`}
                                            className="m-1"
                                            placeholder={`Answer ${answerIndex + 1
                                              }`}
                                            checked={answer.is_correct}
                                            onChange={(ev) => {
                                              values.quizzes[
                                                index
                                              ].answers.forEach(
                                                (x) => (x.is_correct = false)
                                              );
                                              setFieldValue(
                                                `quizzes.${index}.answers.${answerIndex}.is_correct`,
                                                ev.target.checked
                                              );
                                            }}
                                          />
                                          <GlimpseInputBox
                                            id={`quizzes[${index}].answers.${answerIndex}.answer`}
                                            name={`quizzes[${index}].answers.${answerIndex}.answer`}
                                            label=""
                                            type="input"
                                            className="block w-full rounded-xl border-0 py-1.5  mt-1 text-gray-900 ring-0 placeholder:text-gray-400  sm:text-sm sm:leading-6 focus:ring-0 focus:border-input_outline focus:outline-0 h-[32px] pb-3"
                                            placeholder={`Answer ${answerIndex + 1
                                              }`}
                                            maxLength={50}
                                          // showCount={true}
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            )}
                          />
                        </div>
                      );
                    })}
                    <AddNewSection
                      className="w-[130px]"
                      label="Add Section"
                      isEnable={values.quizzes.length < 3}
                      subLabel="Required Sections: 3"
                      clicked={(e: React.MouseEvent<HTMLDivElement>) => {
                        if (values.quizzes.length < 3) {
                          push({
                            question: "",
                            answers: [
                              {
                                answer: "",
                                is_correct: false,
                              },
                              {
                                answer: "",
                                is_correct: false,
                              },
                              {
                                answer: "",
                                is_correct: false,
                              },
                            ],
                          });
                        } else {
                          displayWarning(
                            "You can not add more than 2 Did You Know References."
                          );
                        }
                      }}
                      key="add_new_selection"
                    />
                  </>
                )}
              />
            </div>
            <div className="career-section references">
              <span className="section-title">References</span>
              {errors.references && typeof errors.references === "string" && (
                <ErrorMessage name={"references"} component={InputError} />
              )}
              <div className="max-w-[462px] pt-[11px]">
                {/* <label>Did You Know</label> */}
                {/* <FieldArray
                  name="references"
                  render={({ move, swap, push, insert, unshift, pop }) => (
                    <>
                      {values.references
                        .filter((x) => x.title === References.DidYouKnow)
                        .map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="max-w-[462px] pt-[11px]">
                                <GlimpseInputBox
                                  id={`references.${index}.link`}
                                  name={`references.${index}.link`}
                                  label=""
                                  type="input"
                                  placeholder={"Hyperlink"}
                                />
                              </div>
                            </div>
                          );
                        })}
                      <AddNewSection
                        className="w-[130px]"
                        label="Add Section"
                        isEnable={
                          values.references.filter(
                            (x) => x.title === References.DidYouKnow
                          ).length < 4
                        }
                        subLabel="Required Sections: 4"
                        clicked={(e: React.MouseEvent<HTMLDivElement>) => {
                          if (
                            values.references.filter(
                              (x) => x.title === References.DidYouKnow
                            ).length < 4
                          ) {
                            push({
                              link: "",
                              title: References.DidYouKnow,
                            });
                          } else {
                            displayWarning(
                              "You can not add more than 2 Did You Know References."
                            );
                          }
                        }}
                        key="add_new_selection"
                      />
                    </>
                  )}
                /> */}
                <FieldArray
                  name="references"
                  render={({ move, swap, push, insert, unshift, pop }) => (
                    <>
                      {values.references
                        // .sort((a, b) => {
                        //   if (a.title === References.DidYouKnow) {
                        //     return -1;
                        //   } else {
                        //     return 1;
                        //   }
                        // })
                        .map((item, index) => {
                          return (
                            <div className="max-w-[462px] pt-[11px]" key={index}>
                              <GlimpseInputBox
                                id={`references.${index}.link`}
                                name={`references.${index}.link`}
                                key={index}
                                label={item.title}
                                type="input"
                                as="input"
                                placeholder={`${item.title} Hyperlink`}
                                maxLength={500}
                              />
                            </div>
                          );
                        })}
                    </>
                  )}
                />
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default CareerFormScreen;
