import CareerItem from "components/career-item/career-item.component";
import React from "react";
import { accountingImage } from "utills/image";
import { iconClick } from "utills/image";
const LearnMore = () => {
  return (
    <div>
      <div className="text-blue font-bold text-[24px] lg:mt-[-12px] leading-[30px] text-center">
        Click each career card to learn more.
      </div>
      <div className="flex justify-center items-center w-full py-6">
        {/* <CareerItem
          id={""}
          icon_url={accountingImage}
          
          status={"IN PROGRESS"}
          name={"Accountant"}
          pressed={() => {}}
          statusText={"IN PROGRESS"}
          showStatus={true}
        /> */}
        <img src={iconClick} alt="careercard" />
      </div>
    </div>
  );
};

export default LearnMore;
