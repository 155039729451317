import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import GlimpseCard from "components/card/card.component";
import GlimpseHeader from "components/career-header/career-header.component";
import CareerItem from "components/career-item/career-item.component";
import { displayError } from "components/Toast/toast.component";
import VideoPlayer from "components/video-streaming-onboard/video-player.component";
import { IExpertVoicesString } from "interfaces/CareerInterface";
import { ICareerInfo } from "interfaces/startDriveInterface";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";



import { useLocation } from "react-router-dom";
import {
  deeperDiveLevelChanged,
  getEndUserExportVoice,
  getExportVoices,
  startDeeperDive,
} from "services/deeper-dive.service";
import { noPreviewImage } from "utills/image";
const StartDive = (props: ICareerInfo) => {
  const queryClient = useQueryClient();
  const { title, subtitle, icon_url, description, setIsDisplay, id, isDeepDive } = props;
  const location = useLocation();
  const videoRef = useRef<ReactPlayer>(null);
  const [selectedVideo, setSelectedVideo] = useState<IExpertVoicesString>();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { data: expertVoice, refetch: refetchExpertVoice } = useQuery({
    queryKey: ["getExportVoices"],
    queryFn: async () => {
      return getExportVoices(location.state.career_id);
    },
  });

  const careerId = location.state.career_id;

  const startDeeperDiveMutation = useMutation({
    mutationFn: () => startDeeperDive(careerId), // API call to start the deeper dive
    retry: 0,
    onSuccess: () => {
      // Successfully started deeper dive, set the state to reflect that
      queryClient.invalidateQueries({ queryKey: ['GetCareerForDeeperDive'] })
      setIsDisplay(true);
    },
    onError: (error) => {

    },
  });

  useEffect(() => {
    if (expertVoice?.length) {
      setSelectedVideo(expertVoice[0].expert_voice);
    }
  }, [expertVoice]);

  const [contentHeight, setContentHeight] = useState<number>(0);

  // Function to update height dynamically
  const updateHeight = () => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.clientHeight + 50);
    }
  };

  useEffect(() => {
    // Update height on mount
    updateHeight();

    // Observe changes in content height
    const observer = new ResizeObserver(() => updateHeight());
    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    // Listen for window resize
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
      observer.disconnect();
    };
  }, []);
  let navigate = useNavigate();
  return (
    <div className="p-4 h-full">
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="text-black text-center bg-horizontalRow gap-3 mb-4 w-[80px] h-[35px] rounded-full text-sm font-normal leading-5"
      >
        <div className="flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            viewBox="0 0 12 8"
            fill="none"
          >
            <path
              d="M11 4.5C11.2761 4.5 11.5 4.27614 11.5 4C11.5 3.72386 11.2761 3.5 11 3.5L11 4.5ZM0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659727 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z"
              fill="black"
            />
          </svg>
          <span className="pl-2 font-semibold">Back</span>
        </div>
      </button>
      <div ref={contentRef} className="pb-4">
        <GlimpseHeader
          title={title}
          subtitle={subtitle}
          image={icon_url ? icon_url : noPreviewImage}
        >
          {description}
        </GlimpseHeader>

        <div className="flex flex-col lg:flex-row gap-4 ">
          <div className="w-full lg:w-[500px] ">
            <GlimpseCard title="Expert Voices" childrenClass="h-full">
              <div className="flex items-center justify-center h-full gap-4 ">
                {expertVoice?.length > 1 && (
                  <div className="flex flex-col justify-center ">
                    {expertVoice?.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={` ${
                            selectedVideo?.voice_url === item.voice_url
                              ? "opacity-1 border-2 border-headerBlue p-1"
                              : "opacity-[0.5] p-1"
                            }  block mb-1 rounded-md `}
                        >
                          <img
                            src={
                              item.expert_voice.voice_thumbnail
                                ? item.expert_voice.voice_thumbnail
                                : noPreviewImage
                            }
                            alt="Contribution"
                            className="max-w-[62px] max-h-[110px] md:max-h-[82px]"
                            onClick={() => setSelectedVideo(item.expert_voice)}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="rounded-lg">
                  <VideoPlayer
                    videoRef={videoRef}
                    video_url={selectedVideo?.voice_url as string}
                    thumbnail={selectedVideo?.voice_thumbnail as string}
                    videoStatus="completed"
                    onStart={() => { }}
                    onBuffer={() => { }}
                    onEnd={() => { }}
                    onProgress={(data) => { }}
                    onPaused={() => { }}
                    onSeek={() => { }}
                    className=" !w-[193px] !h-[341px] xl:items-center xl:justify-center xl:flex xl:!w-[150px] xl:!h-[270px]"
                  />
                </div>
              </div>
            </GlimpseCard>{" "}
          </div>
          <div className=" w-full outline-0  rounded-lg flex-col p-3 sm:shadow-card flex justify-center item-center flex items-center ">
            <div className="flex flex-column lg:flex-row  justify-content-center item-center">
              <div className="flex flex-col gap-4 items-center w-full lg:w-[500px]">
                <div className="text-headerBlue text-center font-bold text-[24px]">
                  Dive a little deeper
                </div>
                <div className="text-center text-[16px] leading-[24px]">
                  Learn more about this career’s purpose in the world, day in
                  the life, education requirements, and more!
                </div>
                <button
                  onClick={() => {
                    if (!isDeepDive) {

                      // Only trigger API call if not in deep dive
                      startDeeperDiveMutation.mutate();
                    } else {
                      // Show a message that the user is already in deep dive (do not trigger the API call)
                      setIsDisplay(true);
                    }
                  }}
                  className="px-8 py-2 text-white rounded-full bg-green w-max font-bold text-base"
                >
                  Start Deeper Dive

                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex w-full overflow-hidden p-6"
        style={{
          height: `calc(100vh - ${contentHeight}px)`,
        }}
      >
        <img
          src="/assets/images/blur_image.png"
          alt="Frame 1"
          className="flex-1 max-w-[100%] h-full object-cover object-top blur-md"
        />
      </div>

    </div>
  );
};

export default StartDive;
