import CareerItem from "components/career-item/career-item.component";
import React from "react";
import { accountingImage, engineerImage, teacherImage } from "utills/image";
const Finished = () => {
  return (
    <div>
      <div className="text-blue font-bold text-[24px] lg:mt-[-12px] leading-[30px] text-center">
        Ultimately, identify your Top 3 careers!
      </div>
      <div className="flex gap-8 lg:gap-[44px] justify-center items-center w-full py-6">
        <CareerItem
          id={"1"}
          showRanking={true}
          icon_url={accountingImage}
          rank={1}
          status={"IN PROGRESS"}
          name={"Accountant"}
          pressed={() => {}}
        />
        <CareerItem
          showRanking={true}
          id={"2"}
          rank={2}
          icon_url={engineerImage}
          status={"IN PROGRESS"}
          name={"Accountant"}
          pressed={() => {}}
        />
        <CareerItem
          showRanking={true}
          id={"3"}
          rank={3}
          icon_url={teacherImage}
          status={"IN PROGRESS"}
          name={"Accountant"}
          pressed={() => {}}
        />
      </div>
    </div>
  );
};

export default Finished;
