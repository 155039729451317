import { useMutation, useQuery } from "@tanstack/react-query";
import { SortingState, createColumnHelper } from "@tanstack/react-table";
import {
  displayError,
  displaySuccess,
  displayWarning,
} from "components/Toast/toast.component";
import GlimpseSmallCard from "components/card/smallCard.component";
import DughnutComponent from "components/charts/doughnut.component";
import GlimpseTable from "components/table/table.component";
import {
  IFilterArray,
  IRowItem,
  ITopThreeCareer,
} from "components/table/table.interface";
import { IDownloadReport, IPagination } from "interfaces/BaseInterface";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  deleteInviteMember,
  deleteMember,
  downloadReportFile,
  getAllMembers,
  reInviteMember,
} from "services/members.service";
import { getIndividualDashboardData } from "services/partners.service";
import { IReinviteMember } from "interfaces/AddPartnersInterface";
import GlimpsePopup from "components/popup/popup.component";
import ResetPasswordMember from "components/reset-password/resetPasswordMember";
import Dropdown from "components/dropdown/dropdown.component";
import TimeAgo from "utills/time-ago";
import { PaginationFilter, StatusFilter, Top3Filter } from "enums/glimps.enums";
import { filterItemsDatas } from "utills/Filter-Options";

const IndividualScreen: React.FC = () => {
  const [displayPopupPasswordMember, setDisplayPopupPasswordMember] =
    useState(false);
  const [filterItems, setFilterItems] =
    useState<IFilterArray[]>(filterItemsDatas);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [popupMemberData, setPopupMemberData] = useState<IRowItem | null>(null);
  const [individialPagination, setIndividialPagination] = useState<IPagination>(
    {
      page: 1,
      page_size: 25,
      search: "",
      top_ten: false,
      status: "",
      top_three: "",
      type: "",
      report: "",
      ordering: "",
    }
  );

  // reinvitation
  const reSendInvitationForMember = useMutation({
    mutationFn: (data: IReinviteMember) => reInviteMember(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Member reinvitation has been sent successfully");
    },
    onError: () => {
      displayError("Unable to reinvite member.");
    },
  });

  const handleReinvitationSubmit = (email: string) => {
    const payload = { email: email };
    reSendInvitationForMember.mutateAsync(payload);
  };

  // handlemember delete
  const handleDeleteMember = useMutation({
    mutationFn: (data: string) => deleteMember(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Member has been deleted successfully.");
      refetchindividuslMembers()
    },
    onError: () => {
      displayError("Unable to delete member.");
    },
  });

  const handleDeleteInvitedMember = useMutation({
    mutationFn: (data: string) => deleteInviteMember(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Member has been deleted successfully.");
      refetchindividuslMembers()
    },
    onError: () => {
      displayError("Unable to delete member.");
    },
  });

  const handleDeleteMemberSubmit = (values: IRowItem) => {
    if (values.status === "active") {
      handleDeleteMember.mutateAsync(values.id);
    } else {
      handleDeleteInvitedMember.mutateAsync(values.id);
    }
  };

  useEffect(() => {
    if (sorting.length) {
      let sortData = sorting[0].id;
      if (sortData.endsWith("_count")) {
        sortData = sortData.slice(0, -6);
      }
      setIndividialPagination((prev) => ({
        ...prev,
        ordering: sorting[0].desc ? `-${sortData}` : sortData,
      }));
    } else {
      setIndividialPagination((prev) => ({ ...prev, ordering: "" }));
    }
  }, [sorting]);

  const navigate = useNavigate();
  const columnHelper = createColumnHelper<IRowItem>();
  const individualsMembersColumns = [
    columnHelper.accessor("member", {
      header: "Full Name ",
      cell: (info) => (
        <div className="flex items-center gap-1">
          {info.getValue().avatar && (
            <img
              className="w-[24px] h-[24px]"
              src={info.getValue().avatar}
              alt="career_one_icon"
            />
          )}
          <span>{info.getValue().name}</span>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.top_three, {
      id: "top_three",
      enableSorting: false,
      cell: (info) => (
        <>
          <div className="flex gap-1">
            {info.getValue()
              ? info.getValue().map((item: ITopThreeCareer, index: number) => {
                return (
                  <img
                    key={index}
                    className="min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px]"
                    src={item.icon_url || ""}
                    alt={`Career Icon ${index + 1}`}
                    title={item.name}
                  />
                );
              })
              : ""}
          </div>
        </>
      ),
      header: () => <span>Top Three</span>,
    }),
    columnHelper.accessor("videos_watched", {
      header: () => "Video Previews Watched",
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("modules_completed", {
      header: () => "Deeper Dives Completed",
    }),
    columnHelper.accessor("career_ig_level", {
      header: "Career Awareness",
      enableSorting: false,
      cell: (info) =>
        info.getValue() && (
          <img
            className="w-[24px] h-[24px]"
            src={`/assets/icons/${info
              .getValue()
              .split(" ")[0]
              .toLowerCase()}_badge.png`}
            alt={info.getValue() || "career_three_icon"}
          />
        ),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      enableSorting: false,
      cell: (info) => <span className="capitalize">{info.getValue()}</span>,
    }),
    columnHelper.accessor("last_active", {
      header: "Last Active",
      cell: (info) =>
        info.getValue() ? (
          <span className="capitalize text-nowrap">
            <TimeAgo date={new Date(info.getValue() as string)} />
          </span>
        ) : (
          "--"
        ),
      // <span>
      //   {info.getValue() ? new Date(info.getValue()).toDateString() : "--"}
      // </span>
    }),
    columnHelper.accessor("dob", {
      header: () => "Birth Year",
      cell: (info) => {         
        const value= info.getValue()||''
   

        return<span className="capitalize">{value}</span>},
    }),
    columnHelper.accessor("id", {
      header: "",
      maxSize: 12,
      cell: (info) => {
        const dropdownActivetableItems = [
          {
            label: "Send Email",
            onClick: () =>
              (window.location.href = `mailto:${info.row.original.email}`),
          },
          {
            label: "Reset Password",
            onClick: () => {
              setDisplayPopupPasswordMember(true);
              setPopupMemberData(info.row.original);
            },
          },
          {
            label: "Delete",
            onClick: () => {
              setDeleteAlert(true);
              setPopupMemberData(info.row.original);
            },
          },
        ];

        const dropdownInvitedtableItems = [
          {
            label: "Resend Invite",
            onClick: () => handleReinvitationSubmit(info.row.original.email),
          },
          {
            label: "Delete",
            onClick: () => {
              setDeleteAlert(true);
              setPopupMemberData(info.row.original);
            },
          },
        ];

        return (
          <>
            <Dropdown
              buttonText=""
              onOpened={(data) => { }}
              items={
                info.row.original.status === "active"
                  ? dropdownActivetableItems
                  : dropdownInvitedtableItems
              }
              tableComponent={true}
              svg={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 20C11.45 20 10.9793 19.8043 10.588 19.413C10.196 19.021 10 18.55 10 18C10 17.45 10.196 16.979 10.588 16.587C10.9793 16.1957 11.45 16 12 16C12.55 16 13.021 16.1957 13.413 16.587C13.8043 16.979 14 17.45 14 18C14 18.55 13.8043 19.021 13.413 19.413C13.021 19.8043 12.55 20 12 20ZM12 14C11.45 14 10.9793 13.804 10.588 13.412C10.196 13.0207 10 12.55 10 12C10 11.45 10.196 10.979 10.588 10.587C10.9793 10.1957 11.45 10 12 10C12.55 10 13.021 10.1957 13.413 10.587C13.8043 10.979 14 11.45 14 12C14 12.55 13.8043 13.0207 13.413 13.412C13.021 13.804 12.55 14 12 14ZM12 8C11.45 8 10.9793 7.804 10.588 7.412C10.196 7.02067 10 6.55 10 6C10 5.45 10.196 4.97933 10.588 4.588C10.9793 4.196 11.45 4 12 4C12.55 4 13.021 4.196 13.413 4.588C13.8043 4.97933 14 5.45 14 6C14 6.55 13.8043 7.02067 13.413 7.412C13.021 7.804 12.55 8 12 8Z"
                    fill="#414346"
                  />
                </svg>
              }
            />
          </>
        );
      },
    }),
  ];

  const {
    data: individualMembersList,
    refetch: refetchindividuslMembers,
    isFetching: individualMembersLoading,
  } = useQuery({
    queryKey: ["GetIndividualmembers"],
    queryFn: () => getAllMembers(individialPagination),
    enabled: !!individialPagination,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const downloadReport = useMutation({
    mutationFn: (reportParams: IDownloadReport) =>
      downloadReportFile(reportParams),
    retry: 0,
    mutationKey: ["DownloadReport"],
    onSuccess: (data: Blob) => {
      const link: HTMLElement = document.createElement("a");
      const blob: Blob = new Blob([data]);
      link.setAttribute("href", window.URL.createObjectURL(blob));
      link.setAttribute(
        "download",
        `Individual members Export ${new Date().toDateString()}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onError: () => {
      displayError("Unable to download report, Please contact administrator.");
    },
  });

  useEffect(() => {
    refetchindividuslMembers();
  }, [individialPagination]);

  function handleSearch(data: string) {
    setIndividialPagination((prevData) => ({
      ...prevData,
      search: data,
      page: 1,
      page_size: 25,
    }));
  }

  const { data: individualDashboardData } = useQuery({
    queryKey: ["individualDashboardData"],
    queryFn: () => getIndividualDashboardData(),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  // OVERVIEW CHART PERCENTAGE
  const earnedBadgePercenrtage =
    individualDashboardData &&
      individualDashboardData.earned_badge !== 0 &&
      individualDashboardData.total_individual_users !== 0
      ? (individualDashboardData.earned_badge /
        individualDashboardData.total_individual_users) *
      100
      : 0;

  const assignedTop3 =
    individualDashboardData &&
      individualDashboardData.top_three_assigned !== 0 &&
      individualDashboardData.total_individual_users !== 0
      ? (individualDashboardData.top_three_assigned /
        individualDashboardData.total_individual_users) *
      100
      : 0;

  const chartDataBadge = {
    datasets: [
      {
        data: [earnedBadgePercenrtage, 100 - earnedBadgePercenrtage],
        backgroundColor: ["#D4B07D", "#E2E3E6"],
      },
    ],
  };

  const chartDataTop3 = {
    datasets: [
      {
        data: [assignedTop3, 100 - assignedTop3],
        backgroundColor: ["#06A77D", "#E2E3E6"],
      },
    ],
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="overview font-normal text-[20px] leading-6 ">
          Overview
        </div>
      </div>

      {individualDashboardData && (
        <div className="flex justify-between gap-5 p-2 overflow-auto">
          <GlimpseSmallCard title="Individual Licenses">
            <span className="text-[24px] text-green font-semibold">
              {individualDashboardData.total_individual_users}
            </span>
          </GlimpseSmallCard>
          <GlimpseSmallCard title="Today's Usage">
            <span className="text-[24px] text-green font-semibold">
              {individualDashboardData.todays_usage_hours}
              <span>hrs</span>
            </span>
          </GlimpseSmallCard>
          <GlimpseSmallCard title="Earned Bronze Level">
            <div className="relative flex justify-between">
              <span className="text-[24px] text-green font-semibold">
                {individualDashboardData.earned_badge}
              </span>
              <span className="absolute right-0 h-16 -top-[10px]">
                <DughnutComponent
                  centerPercentage={true}
                  centerValue={`${earnedBadgePercenrtage.toFixed(0)}%`}
                  data={chartDataBadge}
                  fontStyle="400 10px sans-serif"
                />
              </span>
            </div>
          </GlimpseSmallCard>
          <GlimpseSmallCard title="Assigned Top 3">
            <div className="relative flex justify-between">
              <span className="text-[24px] text-green font-semibold">
                {individualDashboardData.top_three_assigned}
              </span>
              <span className="absolute right-0 h-16 -top-[10px]">
                <DughnutComponent
                  centerPercentage={true}
                  centerValue={`${assignedTop3.toFixed(0)}%`}
                  data={chartDataTop3}
                  fontStyle="400 10px sans-serif"
                />
              </span>
            </div>
          </GlimpseSmallCard>
        </div>
      )}
      <div className="flex flex-col gap-3 p-3 overflow-auto w-100">
        <GlimpseTable
          columns={individualsMembersColumns}
          data={individualMembersList ? individualMembersList.results : []}
          totalData={individualMembersList?.count as number}
          currentPage={individialPagination.page}
          isPartner={true}
          totalPage={
            individualMembersList
              ? Math.ceil(
                individualMembersList.count / individialPagination.page_size
              )
              : 0
          }
          enableFilter={true}
          enableExport={true}
          enableSearch={true}
          enablePagination={true}
          preventClickOn={["id"]}
          sorting={sorting}
          setSorting={(sortingData) => {
            setSorting(sortingData);
          }}
          loadingData={individualMembersLoading}
          filterItems={filterItems}
          filterChanged={async (data, key) => {

            setFilterItems(data);
            let values = data.flatMap((group) =>
              group.filters.filter((filter) => filter.is_checked)
            );






            setIndividialPagination((prevData) => ({
              ...prevData,
              status: "",
              top_three: "",
            }));
            values.map(async (item) => {
              if (
                item.name === StatusFilter.Active ||
                item.name === StatusFilter.Invited
              ) {
                setIndividialPagination((prevData) => ({
                  ...prevData,
                  page: 1,
                  status: item.name === StatusFilter.Active ||
                    item.name === StatusFilter.Invited
                    ? item.name
                    : "",
                }));
              }

              if (
                item.name ===PaginationFilter.ten ||
                item.name === PaginationFilter.default || item.name === PaginationFilter.fifty 
              ) {
                setIndividialPagination((prevData) => ({
                  ...prevData,
                  page: 1,
                  page_size: item.name === PaginationFilter.ten ||
                    item.name === PaginationFilter.default || item.name === PaginationFilter.fifty
                    ? item.name
                    : 25,
                }));
              }

              if (
                item.name === Top3Filter.Assigned ||
                item.name === Top3Filter.Unassigned
              ) {
                setIndividialPagination((prevData) => ({
                  ...prevData,
                  page: 1,
                  top_three: item.name === Top3Filter.Assigned ||
                    item.name === Top3Filter.Unassigned
                    ? item.name
                    : "",
                }));
              }





              
            });
          }}
          // filterChanged={async (data, key) => {
          //   setFilterItems(data);
          //   await setIndividialPagination((prevData) => ({
          //     ...prevData,
          //     type: key,
          //   }));
          // }}
          rowSelected={(data) => {
            if (data.status === "active") {
              navigate(`/individuals/individual-insight/${data?.id}`);
            }
          }}
          onSearch={async (data) => {
            setIndividialPagination((prevData) => ({
              ...prevData,
              page: 1,
              search: data.target.value,
            }));
          }}
          nextPage={async () => {
            if (
              Math.ceil(
                individualMembersList
                  ? individualMembersList.count / individialPagination.page_size
                  : 0
              ) <= individialPagination.page
            ) {
              displayWarning("You are at end of the page");
            } else {
              setIndividialPagination((prevData) => ({
                ...prevData,
                page: prevData.page + 1,
              }));
            }
          }}
          previousPage={async () => {
            if (individialPagination.page <= 1) {
              displayWarning("You are already in page one.");
            } else {
              setIndividialPagination((prevData) => ({
                ...prevData,
                page: prevData.page - 1,
              }));
            }
          }}
          exportData={() => {
            downloadReport.mutateAsync({
              search: individialPagination.search
                ? (individialPagination.search as string)
                : null,
              status: individialPagination.status
                ? individialPagination.status
                : null,
              top_three: individialPagination.top_three
                ? individialPagination.top_three
                : null,
            });
          }}
        />
      </div>
      <GlimpsePopup
        title=""
        onClose={() => {
          setDisplayPopupPasswordMember(false);
        }}
        showCross={true}
        popup={displayPopupPasswordMember}
        customClass="max-w-[347px]"
      >
        <div className="text-headerBlue text-[20px] flex items-start">
          Reset Member Password?
        </div>
        {popupMemberData && (
          <ResetPasswordMember
            memberInfo={popupMemberData as IRowItem}
            onClose={() => {
              setDisplayPopupPasswordMember(false);
            }}
          />
        )}
      </GlimpsePopup>
      <GlimpsePopup
        title=""
        onClose={() => {
          setDeleteAlert(false);
        }}
        showCross={false}
        popup={deleteAlert}
        customClass="max-w-[347px]"
      >
        {popupMemberData && (
          <>
            <div className="text-headerBlue text-[20px] flex items-start">
              Delete Member Account
            </div>
            <div>
              If you delete
              <span className="px-1 font-bold">
                {popupMemberData.member.name}'s
              </span>
              membership, their account data will be permanently deleted. This
              action cannot be undone.
            </div>
            <div className="flex justify-between mt-5">
              <button
                type="button"
                onClick={() => setDeleteAlert(false)}
                className="text-green text-center bg-white w-[119px] h-[32px] rounded-full text-sm font-bold leading-5 border border-green"
              >
                Close
              </button>

              <button
                className="text-white text-center bg-red w-[119px] h-[32px] rounded-full text-sm font-normal leading-5"
                onClick={() => {
                  handleDeleteMemberSubmit(popupMemberData);
                  setDeleteAlert(false);
                }}
              >
                Sure Delete
              </button>
            </div>
          </>
        )}
      </GlimpsePopup>
    </>
  );
};
export default IndividualScreen;
