import React from "react";

const WelcomeText = () => {
  return (
    <div>
      <div className="text-blue font-bold text-[24px] lg:mt-[-12px] leading-[30px]">
        Welcome to your careers dashboard
      </div>
      <div className="text-[14px] text-textBlack pt-6 pb-4 leading-[20px] font-bold ">
        Let's show you around to maximize your career awareness discovery and
        get the most out of your Glimpse experience.
      </div>
    </div>
  );
};

export default WelcomeText;
