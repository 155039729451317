import { useQuery } from "@tanstack/react-query";
import GlimpseCard from "components/card/card.component";
import DughnutComponent from "components/charts/doughnut.component";
import React from "react";
import { getUserBadgesReport } from "services/ranking.service";

const GlimpseRankings: React.FC = () => {
  const { data: userBadgeReport } = useQuery({
    queryKey: ["userBadgeReport"],
    queryFn: getUserBadgesReport,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  // const progressPercentage =
  //   userBadgeReport &&
  //   ((userBadgeReport.completed_preview_count /
  //     userBadgeReport.total_preview_count +
  //     userBadgeReport.completed_deeper_dive_count /
  //       userBadgeReport.total_deeper_dive_count) *
  //     100) /
  //     2;

  // calculate center data
  const sideProgressPercentage = userBadgeReport?.preview_progress_percentage
    ? userBadgeReport.preview_progress_percentage
    : 0;

  const previewCenterData = [
    `${userBadgeReport && userBadgeReport.completed_preview_count}/${
      userBadgeReport && userBadgeReport.total_preview_count
    }`,
  ];

  const deeperDivePercentage =
    (userBadgeReport && userBadgeReport.deeper_dive_progress_percentage) ?? 0;

  const deeperdiveCenterData = `${
    userBadgeReport && userBadgeReport.completed_deeper_dive_count
  }/${userBadgeReport && userBadgeReport.total_deeper_dive_count}`;

  //   OVERVIEW CHART PERCENTAGE
  const chartDataPreviewProgress = {
    datasets: [
      {
        data: [sideProgressPercentage, 100 - sideProgressPercentage],
        backgroundColor: ["#06A77D", "#E2E3E6"],
      },
    ],
  };

  const chartDataDeeperDiveProgress = {
    datasets: [
      {
        data: [deeperDivePercentage, 100 - deeperDivePercentage],
        backgroundColor: ["#D4B07D", "#E2E3E6"],
      },
    ],
  };

  return (
    <>
      <div className="grid grid-cols-1 lg:flex lg:flex-row lg:justify-start">
        <div className="order-2 lg:order-1">
          <div className="h-full min-w-full ">
            <div className="md:p-4 ">
              <div className="text-xl font-bold leading-6 text-headerBlue">
                Earned Badges
              </div>
              <div className="flex items-center py-3">
                {userBadgeReport && userBadgeReport.user_badges.length === 0 ? (
                  <div>You haven't earned any badges yet.</div>
                ) : (
                  <>
                    {userBadgeReport &&
                      userBadgeReport.user_badges.map((item, index) => {
                        return (
                          <div className="block p-4" key={index}>
                            <div className="flex flex-col justify-center">
                              <img
                                src={`/assets/icons/${item.slug}_badge.png`}
                                alt="badge"
                                className="flex min-w-[100px] w-[200px] h-auto pl-2"
                              />
                              {/* <span className="text-sm text-center capitalize text-headerBlue ">
                                {item.slug} Level
                              </span> */}
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
            <div className="md:p-4">
              <div className="pb-4 text-xl font-bold leading-6 text-headerBlue">
                Career Awareness Levels
              </div>
              <div className="flex flex-col gap-5 py-4">
                {userBadgeReport &&
                  userBadgeReport.badges?.map((item, index) => {
                    return (
                      <GlimpseCard
                        key={index}
                        className="!p-0 min-w-full h-[150px]"
                        childrenClass="flex min-w-full py-2"
                      >
                        <div className="flex items-center justify-center md:p-2 ">
                          <img
                            src={`/assets/icons/${item.slug}_badge.png`}
                            alt="badge"
                            className="flex min-w-[100px] w-[200px] h-auto pl-2"
                          />
                        </div>
                        <div className="w-full pl-0 pr-2 lg:min-w-[400px] lg:max-w-[600px] ">
                          <div className="font-bold capitalize text-headerBlue">
                            {item.slug}
                          </div>
                          <div className="flex gap-1 text-sm text-headerBlue">
                            <span>
                              Watch {item.preview_count} Previews,
                              {item.deeper_dive_count} Deeper Dives
                            </span>
                          </div>
                          <div className="w-full pt-2 progress">
                            <div className="flex justify-between pb-2 text-sm">
                              <span>
                                {item.completed_preview_count}/
                                {item.preview_count}
                                <span> Previews</span>
                              </span>

                              <div className="flex items-end ">
                                {item.preview_percentage}%
                              </div>
                            </div>
                            <div className="h-2 rounded-md bg-horizontalRow">
                              <div
                                className="h-2 rounded-md bg-green"
                                style={{
                                  width: `${item.preview_percentage.toFixed(
                                    0
                                  )}%`,
                                }}
                              />
                            </div>

                            <div className="flex justify-between pb-2 mt-2 text-sm">
                              <span>
                                {item.completed_deeper_dive_count}/
                                {item.deeper_dive_count}
                                <span> Deeper Dives</span>
                              </span>

                              <div className="flex items-end ">
                                {item.deeper_dive_percentage}%
                              </div>
                            </div>
                            <div className="h-2 min-w-full rounded-md bg-horizontalRow">
                              <div
                                className="h-2 rounded-md bg-green"
                                style={{
                                  width: `${item.deeper_dive_percentage.toFixed(
                                    0
                                  )}%`,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </GlimpseCard>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="order-1 md:p-4 lg:order-2">
          <div className="pb-4 text-xl font-bold leading-6 text-headerBlue">
            Overview
          </div>
          {userBadgeReport && (
            <div className="flex flex-row gap-5 p-2 overflow-auto lg:flex-col charts min-h-[160px] lg:h-auto">
              <GlimpseCard
                className="w-[180px] h-[160px] lg:w-[233px] lg:h-[204px]"
                title="Career Video Progress"
                titleClass="text-sm font-normal text-center  lg:text-lg lg:font-bold text-headerBlue"
                childrenClass="min-w-[180px] lg:w-[225px]"
              >
                <div className="flex items-center justify-center min-w-[103px] h-[103px] lg:min-w-[133px] lg:h-[133px]">
                  <DughnutComponent
                    centerPercentage={true}
                    ratioData={`${previewCenterData} `}
                    centerValue={`${sideProgressPercentage.toFixed(0)}%`}
                    data={chartDataPreviewProgress}
                  />
                </div>
              </GlimpseCard>
              <GlimpseCard
                className="w-[180px] h-[160px] lg:w-[233px] lg:h-[204px]"
                title="Deeper Dive Progress"
                titleClass="text-sm font-normal text-center  lg:text-lg lg:font-bold text-headerBlue"
                childrenClass="min-w-[180px] lg:w-[225px]"
              >
                <div className="flex items-center justify-center min-w-[103px] h-[103px] lg:min-w-[133px] lg:h-[133px]">
                  {/* {deeperDivePercentage && ( */}
                  <DughnutComponent
                    centerPercentage={true}
                    ratioData={`${deeperdiveCenterData}`}
                    centerValue={`${deeperDivePercentage.toFixed(0)}%`}
                    data={chartDataDeeperDiveProgress}
                  />
                </div>
              </GlimpseCard>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GlimpseRankings;
