import { Outlet } from "react-router-dom";
import GlimpseToastContainer from "./components/Toast/toast.component";

const App = () => {
  return (
    <>
      <div className="flex flex-col h-full">
        <GlimpseToastContainer />
        <div className="flex-grow h-[100vh] overflow-hidden">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default App;
