import {  useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { verifyEmail } from 'services/auth.service';
;

const VerifyEmailComponent: React.FC = () => {
  const [searchParams] = useSearchParams();
  // const { token, uid } = useSearchParams();

const token = searchParams.get('token');
const uid = searchParams.get('uid');

  const navigate = useNavigate();

 
 
  const {
    data: partnerList,
    isLoading: isPartnerListLoading,
    refetch,
  } = useQuery({
    queryKey: ["Verification"],
    queryFn: async() => {
      const res= await verifyEmail(uid, token)
    if (res){
      toast.success(res.message);
      navigate(`/login`);
    }
    
    },
    enabled: !!token,
    refetchOnWindowFocus: false,
    retry: 0,
  });



  return (
    <div className="mx-6 my-20 flex bg-bgColor flex-col ">
      <div className="w-full max-w-md m-auto bg-authCard rounded-lg p-10 md:py-10 md:px-16">
        <img className="h-12 object-center mx-auto" src="/assets/images/GlimpseLogo.svg" alt="Logo" />
        <h1 className="text-2xl font-medium text-primary my-8 mb-12 text-center text-white">
          Email Verification
        </h1>

        {/* <div className="w-[7.5rem] h-[2.5rem] flex items-center justify-center rounded-full mx-auto bg-white ">
          <Link to="/login" className="w-fit">
            Login
          </Link>
        </div> */}
      </div>
    </div>
  );
};
export default VerifyEmailComponent;
