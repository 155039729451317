import { useMutation, useQuery } from "@tanstack/react-query";
import { displayWarning } from "components/Toast/toast.component";
import GlimpseHeader from "components/career-header/career-header.component";
import Loading from "components/loading/loading.component";
import { getIntroVideo, unlockOnboard } from "services/on-board";
import { useNavigate } from "react-router-dom";
import VideoPlayer from "components/video-streaming-onboard/video-player.component";
import ReactPlayer from "react-player";
import { useEffect, useRef, useState } from "react";
import { getUserStatus, getVisitStatus, updateUserData, updateUserStatus, updateVisitedUserStatus } from "services/setting.service";
import { IVisitedStatus } from "interfaces/user.interface";
import { useSelector } from "react-redux";


const UserOnBoard: React.FC = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: any) => state);
  const unlockOnBoard = useMutation({
    mutationKey: ["StartOnBoard"],
    mutationFn: () => unlockOnboard(),
    retry: 0,
    onSuccess: async () => {
      localStorage.setItem("hasVisited", "true");
      navigate("/on-boarding/start");
    },
    onError: (err: Error) => {
      console.error(err);
      displayWarning("Unable to unlock careers");
    },
  });

  const { data: instructionVideoInfo, isLoading } = useQuery({
    queryKey: ["getInstructionVideo"],
    queryFn: () => getIntroVideo(),
    refetchOnWindowFocus: false,
    retry: 0,

  });


  const isUnlocking = unlockOnBoard.isPending;

  const startUnlock = () => {
    unlockOnBoard.mutateAsync();
  };
  const [playing, setPlaying] = useState(false)

  const handlePlayPause = () => {
    // setPlaying(true);
    setPlaying((prev) => !prev);
  }

  const videoRef = useRef<ReactPlayer>(null)

  //user visited 
  const[loadings, setLoadings] = useState(true);

  // useEffect(() => {
  //   const hasVisited = localStorage.getItem("hasVisited");
  //   if(hasVisited){
  //     navigate("/on-boarding/start");

  //   }else{
  //     setLoadings(false);
  //   }
  // }, [navigate]);

  // const handleContinue = () => {
  //   localStorage.setItem("hasVisited", "true");
  //   navigate("/on-boarding/start");
  // }

  // if (loadings) return null;
  const [redirecting, setRedirecting] = useState(true); // Prevents flicker
  useQuery({
    queryKey: ["getVisitStatus"],
    queryFn: async () => {
      const response = await getVisitStatus();
      if(response.profile.welcome_video_watched) {
        navigate("/on-boarding/start");
      }else{
        setRedirecting(false); // Allow rendering when navigation is not needed
        setLoadings(false);
      }
      return response;
    },
    enabled: true,
  });

  const handleUpdateVisitStatus = useMutation({
    mutationFn: (data: IVisitedStatus) => updateVisitedUserStatus(data),
    retry: 0,
    onSuccess: async (res) => {
      // navigate("/on-boarding/start");
      
    }
  })


  const handleContinue = () => {
    const payload = {
      welcome_video_watched: true,
    };
    handleUpdateVisitStatus.mutate(payload);
  }
  if (loadings) return null;

  return (
    // <div className="relative flex  content-center  justify-center w-full min-h-[90vh] px-8 overflow-y-auto lg:px-16 lg:pl-6 lg:flex-col lg:items-center lg:justify-center ">
    // <div className="relative flex flex-col content-center justify-center w-full min-h-[90vh] px-8 overflow-y-auto lg:px-16 lg:pl-6 lg:items-center lg:justify-center ">
    <div className="flex flex-col px-6 py-8 lg:px-8 h-[calc(100vh-90px)] overflow-auto ">




      <div className="flex flex-col justify-center  w-full">
        {/* Glimpse Header Section */}
        <div className="flex flex-col items-center">
          <GlimpseHeader
            title="Welcome to Glimpse"
            subtitle="Catch a glimpse of your future"
            titleClass="text-[24px] lg:text-4xl"
            subTitleClass="text-[20px] lg:text-2xl"
            image="/assets/images/GlimpseLogoMark.png"
          />
        </div>

        {/* Flex Row Layout for Video and Steps */}
        <div className="flex flex-col lg:flex-row justify-center w-full mt-4 max-w-full">
          {/* Video Section */}
          <div className="relative w-full lg:max-w-[580px] bg-gray-300 rounded-lg shadow-lg aspect-video lg:max-h-[450px] flex-shrink-0">
            <div className="rounded-lg flex relative w-full h-full">
              <ReactPlayer
                ref={videoRef}
                playing={playing}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                      autoPlay: false
                    }
                  }
                }}
                url={instructionVideoInfo ? instructionVideoInfo[0]?.video_url as string : ' '}
                // light={'/assets/images/videoThumbnail.webp' as string}
                controls={true}

                onReady={() => setPlaying(false)}
                onPlay={() => setPlaying(true)}    // Set playing to true when video starts
                onPause={() => setPlaying(false)}
                // onEnded={() => setPlaying(false)}
                onEnded={() => {
                  setPlaying(false);
                  videoRef.current?.seekTo(0); // Reset video position on end
                }}
                width="100%"
                height="100%"
                className="absolute inset-0 !w-full !h-full !rounded-lg"
              />
              {!playing && (
                <button
                  onClick={handlePlayPause}
                  className="w-16 h-16 bg-white rounded-full shadow-md opacity-75 hover:opacity-100 flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                >
                  {/* SVG Play Button */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="50"
                    height="50"
                    fill="black"
                  >
                    <path d="M8 5v14l11-7z" />
                  </svg>
                </button>
              )}
            </div>

          </div>

          {/* Step Title and Description Section */}
          {/* Step Title and Description Section */}
          {/* <div className="w-full lg:max-w-[400px] lg:max-h-[380px] flex flex-col justify-center lg:pl-4 overflow-auto"> */}
          <div className="w-full lg:max-w-[400px] lg:max-h-[380px] flex flex-col justify-center lg:pl-4 overflow-visible">


            <div className="space-y-5">
              {[
                { step: "1", title: "Watch Each Video", description: "Each career video must be completed before you may continue on to the next one" },
                { step: "2", title: "Sort the Careers", description: "After watching all the videos within a group of 5, sort each career according to your preference." },
                { step: "3", title: "Dive Deeper", description: "After watching all the videos within a group of 5, sort each career according to your preference." },
              ].map(({ step, title, description }) => (
                <div
                  key={step}
                  // className="flex items-start bg-white p-4 rounded-lg mb-4 lg:flex-row flex-col shadow-md lg:shadow-lg"
                    className="flex items-start bg-white p-4 rounded-lg mb-4 lg:flex-row flex-col shadow-md lg:shadow-lg w-[95%] lg:w-[90%] mx-auto"
                >
                  {/* Circle with Step Number */}
                  <div className="flex items-center justify-center w-10 h-10 text-white bg-[#D4B07D] rounded-full flex-shrink-0 mb-4 lg:mb-0 lg:mr-4">
                    {step}
                  </div>

                  {/* Step Title and Description */}
                  <div className="flex flex-col">
                    <h3 className="font-semibold text-[#60849F]">{title}</h3>
                    <p className="text-sm text-gray-600">{description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center">
          <div className="w-full justify-between max-w-[983px] min-h-[65px]  ">

            <div className=" items-center mt-4">
              <button
                type="submit"
                name="save"
                onClick={ () => {startUnlock(); handleContinue();}}
                className="w-full sm:w-[121px]  text-center text-white bg-green border border-green gap-3 px-4 py-2 rounded-full text-sm font-normal leading-5"
              >
                {isUnlocking ? <Loading /> : "Start"}
              </button>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
};

export default UserOnBoard;
