import { CareerTypeEnum } from "enums/glimps.enums";
import axios from "./http-service";
import { MY_CAREER, USER_DASHBOARD_DATA } from "./endpoint-urls";
import {
  IDeeperDiveResponse,
  IEndUserCareer,
  IEndUserExpertVoiceData,
  IPrioritizeCareer,
  IUserCareerSideData,
  IUserDeeperDiveSideData,
} from "interfaces/CareerInterface";

export const getEndUserCareer = (type: CareerTypeEnum) => {
  return axios
    .get<IEndUserCareer[]>(`${MY_CAREER}/list-${type}`)
    .then((res) => res.data);
};
export const getEndUserExportVoice = (user_career_id?: string, id?: string) => {
  return axios
    .get<IEndUserExpertVoiceData>(
      `${MY_CAREER}/${user_career_id}/expert-voice/${id}`
    )
    .then((res) => res.data);
};

export const getExportVoices = (user_career_id?: string) => {
  return axios
    .get(`${MY_CAREER}/${user_career_id}/expert-voices`)
    .then((res) => res.data);
};

export const getEndUserCareerById = (id: string) => {
  return axios.get<any>(`${MY_CAREER}/${id}`).then((res) => res.data);
};

export const completeDeeperDive = (id: string) => {
  return axios
    .put<any>(`${MY_CAREER}/${id}/deeper-dive/complete`, {})
    .then((res) => res.data);
};

export const startDeeperDive = (id: string) => {
  return axios
    .patch<any>(`${MY_CAREER}/${id}/deeper-dive/unlock`, {})
    .then((res) => res.data);
};

export const deeperDiveLevelChanged = (id: string, level: number) => {
  return axios
    .get<IDeeperDiveResponse>(
      `${MY_CAREER}/${id}/deeper-dive/level?level=${level}`
    )
    .then((res) => res.data);
};

// User reprioritize Careers
export const getReprioritizeCareer = () => {
  return axios
    .get<IPrioritizeCareer[]>(`${MY_CAREER}/list-prioritize`)
    .then((res) => res.data);
};

// User Career Side Data
export const getPreviewSideData = () => {
  return axios
    .get<IUserCareerSideData>(`${USER_DASHBOARD_DATA}/preview`)
    .then((res) => {
      return res.data;
    });
};

export const getDeeperDiveSideData = () => {
  return axios
    .get<IUserDeeperDiveSideData>(`${USER_DASHBOARD_DATA}/deep-dive`)
    .then((res) => {
      return res.data;
    });
};
