import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import {
  displayError,
  displaySuccess,
  displayWarning,
} from "components/Toast/toast.component";
import DragAndDrop from "components/drag-and-drop/drag-and-drop.component";
import Loading from "components/loading/loading.component";
import OnboardSorting from "components/onboard-sort/onboard-sort.component";
import GlimpseProgressDots from "components/progress-dots/progress-dots.component";
import VerticalCarousel from "components/scroller/scroller";
import HorizontolCarousel from "components/swiper-carousel/HorizontolCarousel";
import MobileVideoStreaming from "components/video-streaming-onboard/mobile-video-streaming.component";
import VideoStreaming from "components/video-streaming-onboard/video-streaming.component";
import { CareerCategory } from "enums/glimps.enums";
import {
  IMyCareersPrioritize,
  IOnBoardResult,
  IPrioritizeItem,
} from "interfaces/OnbaordingInterface";
import { KeenSliderInstance } from "keen-slider";
import { KeenSliderHooks } from "keen-slider/react";
import { MutableRefObject, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import { WSS_BASE_URL } from "services/endpoint-urls";
import {
  getCountReport,
  prioritizeCareer,
  startOnBoard,
} from "services/on-board";

const UserOnBoardVideo: React.FC = () => {
  const [userPage, setUserPage] = useState<number>(1);
  const [open, setOpen] = useState<boolean | null>(null);
  const [enableNext, setEnableNext] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [intervalCount, setIntervalCount] = useState<number>(0);
  const [mobileIndex, setMobileIndex] = useState<number>(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [onBoardingVideos, setOnBoardingVideos] = useState<IOnBoardResult[]>(
    []
  );
  const [prioritizeList, setPrioritizeList] =
    useState<IMyCareersPrioritize[]>();

  const { sendJsonMessage } = useWebSocket(WSS_BASE_URL);
  const navigate = useNavigate();

  const enableNextButton = () => {
    setEnableNext(true);
  };

  useEffect(() => {
    const localStorageValue = localStorage.getItem("userInfo");
    if (localStorageValue) {
      const userInfo: any = JSON.parse(localStorageValue);
      const { access } = userInfo;
      sendJsonMessage({
        action: "authenticate",
        token: access,
      });
    }
    return () => {
      setOnBoardingVideos([]);
    };
  }, []);

  const {
    data: onBoardData,
    status,
    refetch: refetchOnBoard,
  } = useQuery({
    queryKey: ["StartOnBoard"],
    queryFn: async () => {
      const fetchedData = await startOnBoard(userPage);
      enableNextButton();
      return fetchedData;
    },
    staleTime: 0,
    retry: 0,
    enabled: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (onBoardData) {
      !onBoardingVideos.find((x) => x.id === onBoardData.results[0].id) &&
        setOnBoardingVideos([...onBoardingVideos, onBoardData.results[0]]);
      if (onBoardData.next && onBoardData.results[0].is_previewed) {
        nextClicked();
      }
    }
  }, [onBoardData]);

  useEffect(() => {
    refetchOnBoard();
  }, [userPage]);

  const prioritizeDraggedCareer = useMutation({
    mutationFn: (data: IPrioritizeItem) => prioritizeCareer(data),
    retry: 0,
    onSuccess: (resultdata: IPrioritizeItem[]) => {
      refetchReportCount();
    },
  });

  const { data: reportCount, refetch: refetchReportCount } = useQuery({
    queryKey: ["GetCountReport"],
    queryFn: () => getCountReport(),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const nextClicked = () => {
    setEnableNext(false);
    if (!onBoardData?.next) {
      if (window.location.pathname.includes("prioritize/unlock-next")) {
        navigate("/prioritize/unlock-next/complete");
      } else if (
        window.location.pathname.includes("user-careers/unlock-next")
      ) {
        navigate("/user-careers/unlock-next/complete");
      } else {
        navigate("/on-boarding/complete");
      }
    } else {
      setUserPage((prevData) => {
        return prevData + 1;
      });
    }
  };

  const prevClicked = (
    ref?: MutableRefObject<KeenSliderInstance<{}, {}, KeenSliderHooks> | null>
  ) => {
    ref?.current?.prev();
    // setCurrentSlide((prevData) => {
    //   return prevData - 1;
    // });
  };

  const gotoNext = (
    type: boolean,
    ref?: MutableRefObject<KeenSliderInstance<{}, {}, KeenSliderHooks> | null>
  ) => {
    if (
      onBoardData?.count === mobileIndex + 1 &&
      onBoardingVideos[mobileIndex].is_previewed
    ) {
      if (window.location.pathname.includes("prioritize/unlock-next")) {
        navigate("/prioritize/unlock-next/complete");
      } else if (
        window.location.pathname.includes("user-careers/unlock-next")
      ) {
        navigate("/user-careers/unlock-next/complete");
      } else {
        navigate("/on-boarding/complete");
      }
    } else {
      ref?.current?.next();
      // setCurrentSlide((prevData) => {
      // return prevData + 1;
      // });
    }
  };

  const itemDropped = (data: IMyCareersPrioritize) => {
    let obj = {
      category: data.category as CareerCategory,
      order: 0,
      id: data.id,
    };
    if (
      reportCount &&
      data.category === "top_three" &&
      reportCount.top_three >= 3
    ) {
      let originalItem = onBoardingVideos.find((x) => x.id === data.id);
      if (reportCount && reportCount.top_three >= 3) {
        data.category = originalItem?.category as CareerCategory;
        displayWarning(
          "Your Top 3 is full. Please place in one of the other categories."
        );
      }
    } else if (reportCount) {
      switch (data.category) {
        case CareerCategory.discard:
          obj.order = reportCount.discard + 1;
          break;
        case CareerCategory.top_three:
          obj.order = reportCount.top_three + 1;
          break;
        case CareerCategory.watch_list:
          obj.order = reportCount.watch_list + 1;
          break;
        default:
          break;
      }

      prioritizeDraggedCareer.mutateAsync(obj);
      onBoardingVideos[mobileIndex].category = obj.category;
    }
  };

  return (
    <>
      <div className="block md:hidden">
        <div className="relative flex content-center justify-center w-full">
          <div className="flex flex-col justify-center">
            <>
              {status === "error" && <>Error Occured</>}
              {status === "success" && onBoardData.results.length && (
                <GlimpseProgressDots
                  isVertical={true}
                  currentSlide={mobileIndex + 1}
                  enableNext={
                    enableNext && onBoardingVideos[mobileIndex]?.is_previewed
                  }
                  enableQuiz={false}
                  totalSlides={onBoardData.count}
                  onNext={gotoNext}
                  enableSorting={true}
                  handleFinish={() => {}}
                  onPrev={prevClicked}
                  handleSortNow={() => {
                    setPrioritizeList([
                      {
                        id: onBoardingVideos[mobileIndex].id,
                        category: onBoardingVideos[mobileIndex].category,
                        order: onBoardingVideos[mobileIndex].order,
                        career_id: onBoardingVideos[mobileIndex].career.id,
                        name: onBoardingVideos[mobileIndex].career.name,
                        icon_url: onBoardingVideos[mobileIndex].career.icon_url,
                        icon: onBoardingVideos[mobileIndex].career.icon,
                      },
                    ]);
                    setOpen(true);
                  }}
                >
                  <>
                    <VerticalCarousel
                      enableNext={
                        enableNext &&
                        onBoardingVideos[mobileIndex]?.is_previewed
                      }
                      handleBack={prevClicked}
                      handleNext={gotoNext}
                      totalSlides={onBoardData.count}
                      handleSortNow={() => {
                        setPrioritizeList([
                          {
                            id: onBoardingVideos[mobileIndex].id,
                            category: onBoardingVideos[mobileIndex].category,
                            order: onBoardingVideos[mobileIndex].order,
                            career_id: onBoardingVideos[mobileIndex].career.id,
                            name: onBoardingVideos[mobileIndex].career.name,
                            icon_url:
                              onBoardingVideos[mobileIndex].career.icon_url,
                            icon: onBoardingVideos[mobileIndex].career.icon,
                          },
                        ]);
                        setOpen(true);
                      }}
                      items={onBoardingVideos.sort(
                        (a: IOnBoardResult, b: IOnBoardResult) =>
                          a.career.order - b.career.order
                      )}
                      currentSlide={currentSlide}
                      setCurrentSlide={setCurrentSlide}
                      setIntervalCount={setIntervalCount}
                      onEnd={() => {
                        sendJsonMessage({
                          action: "video_progress",
                          progress: {
                            id: onBoardingVideos[mobileIndex].cover_video.id,
                            progress: currentTime,
                            status: "completed",
                          },
                        });
                        setTimeout(() => {
                          onBoardingVideos[mobileIndex].is_previewed = true;
                          if (!onBoardData.next) {
                            // if (window.location.pathname.includes("unlock-next")) {
                            //   navigate("/prioritize/unlock-next/complete");
                            // } else {
                            //   navigate("/on-boarding/complete");
                            // }
                          } else {
                            if (userPage === mobileIndex + 1) {
                              setUserPage((prevData) => {
                                return prevData + 1;
                              });
                            }
                          }
                        }, 2000);
                      }}
                      onStart={() => {
                        sendJsonMessage({
                          action: "video_progress",
                          progress: {
                            id: onBoardingVideos[mobileIndex].cover_video.id,
                            progress: 0,
                            status: "started",
                          },
                        });
                      }}
                      onProgress={async (data) => {
                        if (
                          data.playedSeconds >= intervalCount + 5 &&
                          Math.trunc(data.playedSeconds % 5) === 0 &&
                          Math.trunc(data.playedSeconds) !== 0
                        ) {
                          sendJsonMessage({
                            action: "video_progress",
                            progress: {
                              id: onBoardingVideos[mobileIndex].cover_video.id,
                              progress: 5, // In every 5 seconeds
                              status: "watching",
                            },
                          });
                          setIntervalCount((prev) => prev + 5);
                        }
                        setCurrentTime(data.playedSeconds);
                      }}
                      setMobileIndex={setMobileIndex}
                      onBuffer={() => {}}
                      onPaused={() => {}}
                      onError={() => {}}
                    />
                  </>
                </GlimpseProgressDots>
              )}
            </>
          </div>
        </div>
      </div>

      <div className="hidden md:block w-full max-w-[1400px] mx-auto">
        <HorizontolCarousel
          items={onBoardingVideos.sort(
            (a: IOnBoardResult, b: IOnBoardResult) =>
              a.career.order - b.career.order
          )}
          currentSlide={currentSlide}
          count={onBoardData?.count as number}
          setMobileIndex={setMobileIndex}
          onStart={() => {
            sendJsonMessage({
              action: "video_progress",
              progress: {
                id: onBoardingVideos[mobileIndex].cover_video.id,
                progress: 0,
                status: "started",
              },
            });
          }}
          onBuffer={() => {}}
          onPaused={() => {}}
          onError={() => {}}
          onEnd={() => {
            sendJsonMessage({
              action: "video_progress",
              progress: {
                id: onBoardingVideos[mobileIndex].cover_video.id,
                progress: currentTime,
                status: "completed",
              },
            });
            setTimeout(() => {
              onBoardingVideos[mobileIndex].is_previewed = true;
              if (!onBoardData?.next) {
                // if (window.location.pathname.includes("unlock-next")) {
                //   navigate("/prioritize/unlock-next/complete");
                // } else {
                //   navigate("/on-boarding/complete");
                // }
              } else {
                if (userPage === mobileIndex + 1) {
                  setUserPage((prevData) => {
                    return prevData + 1;
                  });
                }
              }
            }, 2000);
          }}
          onProgress={async (data) => {
            if (
              data.playedSeconds >= intervalCount + 5 &&
              Math.trunc(data.playedSeconds % 5) === 0 &&
              Math.trunc(data.playedSeconds) !== 0
            ) {
              sendJsonMessage({
                action: "video_progress",
                progress: {
                  id: onBoardingVideos[mobileIndex].cover_video.id,
                  progress: 5, // In every 5 seconeds
                  status: "watching",
                },
              });
              setIntervalCount((prev) => prev + 5);
            }
            setCurrentTime(data.playedSeconds);
          }}
          enableNext={enableNext && onBoardingVideos[mobileIndex]?.is_previewed}
          handleSortNow={() => {
            setPrioritizeList([
              {
                id: onBoardingVideos[mobileIndex].id,
                category: onBoardingVideos[mobileIndex].category,
                order: onBoardingVideos[mobileIndex].order,
                career_id: onBoardingVideos[mobileIndex].career.id,
                name: onBoardingVideos[mobileIndex].career.name,
                icon_url: onBoardingVideos[mobileIndex].career.icon_url,
                icon: onBoardingVideos[mobileIndex].career.icon,
              },
            ]);
            setOpen(true);
          }}
        />
        {/* <SwiperCarousel
          items={onBoardingVideos.sort(
            (a: IOnBoardResult, b: IOnBoardResult) =>
              a.career.order - b.career.order
          )}
          currentSlide={currentSlide}
          onStart={() => {
            sendJsonMessage({
              action: "video_progress",
              progress: {
                id: onBoardingVideos[mobileIndex].cover_video.id,
                progress: 0,
                status: "started",
              },
            });
          }}
          onBuffer={() => {}}
          onPaused={() => {}}
          onError={() => {}}
          onEnd={() => {
            sendJsonMessage({
              action: "video_progress",
              progress: {
                id: onBoardingVideos[mobileIndex].cover_video.id,
                progress: currentTime,
                status: "completed",
              },
            });
            onBoardingVideos[mobileIndex].is_previewed = true;
            if (!onBoardData?.next) {
              if (window.location.pathname.includes("unlock-next")) {
                navigate("/prioritize/unlock-next/complete");
              } else {
                navigate("/on-boarding/complete");
              }
            } else {
              if (userPage === mobileIndex + 1) {
                setUserPage((prevData) => {
                  return prevData + 1;
                });
              }
            }
          }}
          onProgress={async (data) => {
            if (
              data.playedSeconds >= intervalCount + 5 &&
              Math.trunc(data.playedSeconds % 5) === 0 &&
              Math.trunc(data.playedSeconds) !== 0
            ) {
              sendJsonMessage({
                action: "video_progress",
                progress: {
                  id: onBoardingVideos[mobileIndex].cover_video.id,
                  progress: 5, // In every 5 seconeds
                  status: "watching",
                },
              });
              setIntervalCount((prev) => prev + 5);
            }
            setCurrentTime(data.playedSeconds);
          }}
          enableNext={enableNext && onBoardingVideos[mobileIndex]?.is_previewed}
          // handleSortNow={() => {
          //   setPrioritizeList([
          //     {
          //       id: onBoardingVideos[mobileIndex].id,
          //       category: onBoardingVideos[mobileIndex].category,
          //       order: onBoardingVideos[mobileIndex].order,
          //       career_id: onBoardingVideos[mobileIndex].career.id,
          //       name: onBoardingVideos[mobileIndex].career.name,
          //       icon_url: onBoardingVideos[mobileIndex].career.icon_url,
          //       icon: onBoardingVideos[mobileIndex].career.icon,
          //     },
          //   ]);
          //   setOpen(true);
          // }}
        /> */}
      </div>
    </>
  );
};

export default UserOnBoardVideo;
