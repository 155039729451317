function PublicHeader() {
  const canAccess = localStorage.getItem('userInfo')
  const handleSubmit = async (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    await localStorage.removeItem("userInfo");
    window.location.href = "/";
  };
 
  return (
    <>
      <nav className="flex justify-between sm:p-5 h-[90px] bg-lightBlue">

        <div id="glimpse-header" className="flex items-center justify-start">
          <img
            className="min-h-[35px] me-3 px-4 sm:min-h-[40px]"
            src="/assets/images/GlimpseLogo_White.svg"
            alt="Glimpse"
          />
        </div>
        {canAccess && <div className="h-auto md:h-full  flex items-center">
          <button className="px-4 py-2 h-auto bg-white/10 text-white border border-white/20 backdrop-blur-md rounded-lg hover:bg-white/20 transition mr-4 md:mr-0" onClick={handleSubmit}>Logout</button>
        </div>}
      </nav>
    </>
  );
}

export default PublicHeader;
