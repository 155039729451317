import { useState } from "react";
import Tabs from "../../components/tabs/tabs.component";
import { ITabItem } from "../../components/tabs/tabs.interface";
import { registrationEnum } from "../../enums/glimps.enums";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAllSubs, createNewUser } from "../../services/auth.service";
import {
  IGiftInvitation,
  IUserRegistration,
} from "../../interfaces/user.interface";
import { ISubscription } from "../../interfaces/subscription.interface";
import {
  displayError,
  displaySuccess,
  displayWarning,
} from "components/Toast/toast.component";
import PaymentScreen from "components/stripe-elements/payment.screen";
import RegisterUser from "components/register-user/resgister-user.component";
import HelpSomeoneLearn from "components/register-user/help-someone-learn.component";
import ChoosePlan from "components/register-user/choose-plan.component";
import RedeemCode from "components/register-user/redeem-code.component";
import { FormikHelpers } from "formik";
import {
  sendGiftInvitation,
  validateGiftInvitation,
} from "services/gift-invitation.service";
import { EmailValues, ICode } from "interfaces/RegisterInterface";
import { useLocation, useNavigate } from "react-router";
import { IPriceInfo } from "interfaces/PaymentInterface";
import { SelectOption } from "components/input/glimpse-input.interface";

const tabsList: ITabItem[] = [
  {
    icon: "assets/icons/boy_with_glass.svg",
    name: "For Me",
  },
  {
    icon: "assets/icons/gift_pack.svg",
    name: "For Someone Else",
  },
  {
    icon: "/assets/icons/LoginCheck.svg",
    name: "Redeem a Code",
  },
];
const initialEmailValues: EmailValues = { emailList: [""] };

const RegisterScreen: React.FC = () => {
  const { search } = useLocation();
  const getTypeAsNumber = () => {
    const typeString = search.split("type=")[1];
    const numberValue = parseInt(typeString, 10);
    return numberValue;
  };
  const typeAsNumber = getTypeAsNumber();
  const getRedeemCode = search.split("&code=")[1];

  const [activeTab, setActiveTab] = useState(typeAsNumber ? typeAsNumber : 0);
  const [redeemCode, setRedeemCode] = useState(
    getRedeemCode ? getRedeemCode : ""
  );

  const [userInfo, setUserInfo] = useState<IUserRegistration>({
    avatar: "",
    email: "",
    first_name: "",
    id: null,
    last_name: "",
    password: "",
    subscription_id: "",
    confirm_password: "",
    client_secret: "",
    dob: "",
  });
  const [email, setEmail] = useState<EmailValues>(initialEmailValues);
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const [priceInfo, setPriceInfo] = useState<IPriceInfo>();

  const [activeRegStep, setActiveRegStep] = useState(
    registrationEnum.subscription
  );

  const { data: subscriptionList, status } = useQuery({
    queryKey: ["getAllSubs"],
    queryFn: getAllSubs,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const tabChanged = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    setActiveTab(index);
    setActiveRegStep(registrationEnum.subscription);
  };
  const onPlanSelect = (item: ISubscription, index: number) => {
    setUserInfo((prevData) => ({ ...prevData, subscription_id: item.id }));
    setActiveRegStep(registrationEnum.user);
  };

  const handleValueChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setUserInfo((prevData) => ({ ...prevData, [name]: value }));
  };

  const registerUser = useMutation({
    mutationFn: (userDetails: IUserRegistration) => {
      setUserInfo(userDetails)
      return createNewUser(userDetails)
    },
    retry: 0,
    onSuccess: (e) => {
      if (e.client_secret) {
        setClientSecret(e.client_secret);
        setPriceInfo(e);
        setActiveRegStep(registrationEnum.payment);
        displayWarning(
          "Your account has been created. Please proceed to payment for activation."
        );
      } else {
        displaySuccess("Your code has reedemed susscessully");
        navigate("/login");
      }
    },
  });

  const handleSubmit = async (e: IUserRegistration) => {
    await registerUser.mutateAsync(e);
  };

  const handleEmails = useMutation({
    mutationFn: (data: IGiftInvitation) => sendGiftInvitation(data),
    retry: 0,
    onSuccess: (ev: IPriceInfo) => {
      setClientSecret(ev.client_secret);
      setPriceInfo(ev);
      // setEmail(initialEmailValues);
      setActiveRegStep(registrationEnum.payment);
    },
    onError: () => {
      displayError("Sending invitation failed");
    },
  });

  const handleGiftInvitation = async (
    emails: EmailValues,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    setEmail(emails);
    const values = {
      emails: emails.emailList,
      subscription_id: userInfo.subscription_id,
    };
    setSubmitting(false);
    await handleEmails.mutateAsync(values);
  };

  const handleRedeemCode = useMutation({
    mutationFn: (code: ICode) => validateGiftInvitation(code),
    retry: 0,
    onSuccess: (data) => {
      displaySuccess(
        "You have successfully redeemed invitation code. Please Signup and get started."
      );
      setActiveRegStep(registrationEnum.user);
      setUserInfo((prev) => ({
        ...prev,
        subscription_id: data.subscription_id,
        invitation_id: data.invitation_id,
        email: data.email,
      }));
    },
    onError: () => { },
  });

  const handleRedeemCodeSubmit = async (code: string) => {
    setRedeemCode(code);
  };

  const generateYearOptions = (
    startYear: number,
    endYear: number
  ): SelectOption[] => {
    const options: SelectOption[] = [];
    for (let year = endYear; year >= startYear; year--) {
      options.push({ value: year.toString(), label: year.toString() });
    }
    return options;
  };

  //calculate how many years to show for dob
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 100;
  const options = generateYearOptions(startYear, currentYear);

  return (
    <>
      <div className="flex flex-col lg:px-8  bg-background h-[calc(100vh-150px)] sm:w-[calc(100vw-250px)]  overflow-auto">
        <div>
          {activeRegStep !== registrationEnum.payment &&
            activeRegStep !== registrationEnum.user && (
              <Tabs
                items={tabsList}
                onClick={tabChanged}
                activeIndex={activeTab}
                opacity={true}
              />
            )}
        </div>
        <div className="w-full h-full">
          {activeRegStep === registrationEnum.subscription &&
            activeTab !== 2 && (
              <ChoosePlan
                status={status}
                onPlanSelect={onPlanSelect}
                subscriptionList={subscriptionList}
                activeTab={activeTab}
              />
            )}
          {activeRegStep === registrationEnum.user &&
            (activeTab === 0 || activeTab === 2) && (
              <RegisterUser
                userInfo={userInfo}
                handleValueChanged={handleValueChanged}
                handleSubmit={handleSubmit}
                options={options}
              />
            )}
          {activeRegStep === registrationEnum.user && activeTab === 1 && (
            <HelpSomeoneLearn
              setActiveRegStep={setActiveRegStep}
              setActiveTab={setActiveTab}
              handleGiftInvitation={handleGiftInvitation}
              email={email}
            />
          )}
          {activeRegStep === registrationEnum.payment && (
            <PaymentScreen
              onGoback={() => {
                setActiveRegStep(registrationEnum.user);
              }}
              clientSecret={clientSecret}
              email={userInfo.email}
              priceInfo={priceInfo}
              onSuccess={async () => {
                // if (activeTab === 1) {
                //   displaySuccess("Payment has been completed.");
                // }
                // else {
                displaySuccess(
                  "Payment has been completed. You can now login to you account!"
                );
                // }
                await localStorage.removeItem("userInfo");
                navigate("/login");
              }}
            />
          )}
          {activeTab === 2 && activeRegStep !== registrationEnum.user && (
            <RedeemCode
              handleRedeemCodeSubmit={handleRedeemCodeSubmit}
              redeemCode={redeemCode}
              handleSubmit={() => {
                handleRedeemCode.mutateAsync({
                  code: redeemCode,
                });
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default RegisterScreen;
