import {
  FileType,
  IMediaUploadResponse,
  IMediaPicker,
} from "../../interfaces/MediaPickerInterface";
import { useMutation } from "@tanstack/react-query";
import { updateMedia, uploadMedia } from "../../services/career.service";
import Loading from "../loading/loading.component";
import { useState } from "react";
import InputError from "components/input-error/input-error";
import { ErrorMessage } from "formik";
import { ImageType } from "enums/glimps.enums";
import {useRef} from "react";
const ImagePicker: React.FC<IMediaPicker> = ({
  id,
  name,
  className,
  svgImgClassName,
  svgPenClassName,
  svgHeight,
  svgWidth,
  onFileUploaded,
  enableAutoUpload,
  media,
  order,
  mediaId,
  image_type,
}) => {
  const [selectedImage, setSelectedImage] = useState<string | undefined>();
  const uploadNewImage = useMutation({
    mutationFn: (imageFile: FileType) => {
      if (mediaId) {
        return updateMedia(imageFile, mediaId);
      } else {
        return uploadMedia(imageFile);
      }
    },
    retry: 0,
    onSuccess: (data: IMediaUploadResponse) => {
      onFileUploaded(data, order);
      setSelectedImage(data.file);
    },
  });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const isLoading = uploadNewImage.isPending;
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedImage(undefined);
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0) {
      const selectedFile = fileInput.files[0];
      let formData = new FormData();
      formData.append(
        image_type === "career_video" ? "thumbnail" : "file",
        selectedFile,
        selectedFile.name
      );
      formData.append(
        "type",
        image_type ? image_type : `${ImageType.career_photo}`
      );
      if (enableAutoUpload) {
        uploadNewImage?.mutateAsync(formData);
      } else {
        const reader = new FileReader();

        reader.readAsDataURL(selectedFile);

        reader.onload = (e) => {
          const result = e.target?.result;
          setSelectedImage(result as string);
          onFileUploaded(selectedFile, 1);
        };
      }
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

  };

  return (
    <>
      <div className={`flex items-center justify-center ${className}`}>
        <label
          htmlFor={`image ${name}${order}`}
          className="w-full h-full rounded-md bg-lightBrown "
        >
          <div
            className={`flex flex-col items-center justify-center pt-5 pb-6 cursor-pointer  ${svgImgClassName}`}
          >
            {isLoading ? (
              <Loading />
            ) : media || selectedImage ? (
              <img
                src={selectedImage || media}
                alt="Glimpse_Media_Picker"
                className={`absolute rounded-md top-0 ${svgImgClassName}`}
              />
            ) : (
              <svg
                width="26"
                height="18"
                viewBox="0 0 26 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-[30px]"
              >
                <path
                  d="M25.7955 2.76267C25.3366 1.40638 24.1268 0.371779 22.6787 0.100896C21.9198 -0.0410812 3.94047 -0.0351995 3.19824 0.131121C1.73874 0.458288 0.554237 1.56502 0.165985 2.96559C-0.037848 3.7008 -0.0166629 13.516 0.0348227 14.2886C0.131295 15.7392 1.07796 17.051 2.44697 17.6451C3.1481 17.9494 3.85919 17.9921 4.61544 17.9922C5.43862 17.9923 6.26172 17.9927 7.08481 17.9934C8.73109 17.9946 10.3773 17.9965 12.0235 17.998C15.3159 18.0009 18.6083 18.002 21.9008 17.9922C22.5861 17.9922 23.264 17.8251 23.8674 17.5058C25.1679 16.8176 25.998 15.4629 25.998 14.028C25.998 12.4402 26.0338 3.46716 25.7955 2.76267ZM24.1374 15.0262C23.8414 15.646 23.2711 16.1242 22.593 16.315C21.6868 14.9103 20.7773 13.5074 19.8694 12.1036C19.0708 10.8687 16.7126 7.22252 16.6747 7.16387C16.3954 6.73189 15.7013 6.65413 15.3624 7.06731C15.1391 7.3395 12.4109 11.612 11.8248 12.5182C11.4855 12.0772 10.5524 10.8493 10.4148 10.6856C10.0796 10.2811 9.43475 10.2559 9.10389 10.6859C8.90867 10.9397 5.13865 15.84 4.81826 16.2564C4.67554 16.4419 4.69174 16.4126 4.40292 16.4117C3.6584 16.4095 2.98065 16.266 2.42426 15.7535C1.87016 15.243 1.66118 14.5747 1.65907 13.8511C1.65671 13.0568 1.63662 3.2814 2.09864 2.63524C2.50487 2.07019 3.15671 1.6954 3.86443 1.62326C4.66043 1.54133 20.302 1.60595 21.1258 1.60799C21.8834 1.60987 22.6043 1.58471 23.2626 2.00729C23.8702 2.39719 24.2738 3.04262 24.3509 3.74409C24.4329 4.50773 24.3708 11.214 24.3674 12.8026C24.3658 13.539 24.4658 14.3388 24.1374 15.0262Z"
                  fill="#A3A3A3"
                />
              </svg>
            )}
            <p className="mt-2 ml-16 ">
              <label className={`font-semibold absolute ${svgPenClassName}`}>
                <svg
                  width={svgWidth}
                  height={svgHeight}
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.25 20.25C23.9779 16.5221 23.9779 10.4779 20.25 6.75C16.5221 3.02208 10.4779 3.02208 6.75 6.75C3.02208 10.4779 3.02208 16.5221 6.75 20.25C10.4779 23.9779 16.5221 23.9779 20.25 20.25Z"
                    fill="#404040"
                  />
                  <path
                    d="M17.9202 10.16L16.8398 9.07959C16.4603 8.70003 15.843 8.70003 15.4635 9.07959L14.5718 9.97129L17.0285 12.428L17.9202 11.5363C18.104 11.3526 18.2052 11.108 18.2052 10.8483C18.2052 10.5885 18.104 10.344 17.9202 10.16Z"
                    fill="white"
                  />
                  <path
                    d="M11.3637 13.2921L11.0724 15.4953C11.0567 15.6141 11.0971 15.7335 11.1819 15.8182C11.2547 15.891 11.3529 15.9312 11.4544 15.9312C11.4713 15.9312 11.4879 15.9302 11.5048 15.9278L13.708 15.6364C13.792 15.6254 13.8699 15.5869 13.93 15.5269L16.484 12.9729L14.0273 10.5161L11.4732 13.0701C11.4132 13.1302 11.3747 13.2081 11.3637 13.2921Z"
                    fill="white"
                  />
                  <path
                    d="M17.3403 14.5123C17.1276 14.5123 16.9548 14.6848 16.9548 14.8975V16.6444C16.9548 17.0803 16.6002 17.4349 16.1643 17.4349H10.3554C9.91953 17.4349 9.56496 17.0803 9.56496 16.6444V10.8358C9.56496 10.3999 9.91953 10.0453 10.3554 10.0453H12.1023C12.315 10.0453 12.4878 9.87279 12.4878 9.6601C12.4878 9.44741 12.3153 9.2749 12.1023 9.2749H10.3554C9.49463 9.2749 8.79431 9.97522 8.79431 10.836V16.6447C8.79431 17.5055 9.49463 18.2058 10.3554 18.2058H16.1643C17.0251 18.2058 17.7255 17.5055 17.7255 16.6447V14.8978C17.7255 14.6851 17.5529 14.5126 17.34 14.5126L17.3403 14.5123Z"
                    fill="white"
                  />
                </svg>
              </label>
              <label className="hidden">Glimpse Image Upload</label>
            </p>
          </div>

          <input
            ref={fileInputRef}
            id={`image ${name}${order}`}
            name={`image ${name}${order}`}
            type="file"
            className="hidden"
            accept="image/*"
            onChange={ handleImageChange}
          />
        </label>
      </div>
      <ErrorMessage name={name} component={InputError} />
    </>
  );
};
export default ImagePicker;
