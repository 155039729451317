import Tooltip from "components/tooltip/tooltip.component";
import { ICareerItem } from "../../interfaces/CareerInterface";

const CareerItem: React.FC<ICareerItem> = ({
  name,
  icon_url,
  id,
  pressed,
  showRanking,
  rank,
  isAdmin,
  showStatus = false,
  statusText,
}: ICareerItem) => {
  const isLongName = name?.length > 25;
  const truncatedName = isLongName ? name.slice(0, 8) + "..." : name;

  return (
    <div
      id={id}
      // className="relative bg-widgetBlue w-[105px] h-[147px] flex flex-col rounded-md  text-center pb-[10px] pt-[10px] items-center gap-2 shadow-lg cursor-pointer select-none"
      className="relative bg-widgetBlue w-[105px] h-[147px] flex flex-col rounded-md text-center pb-[10px] pt-[10px] items-center gap-2 shadow-lg cursor-pointer select-none before:absolute before:w-[87px] before:h-[87px] before:bg-white before:blur-sm before:rounded-full before:opacity-35 before:content-[''] before:top-[calc(32%-42px)] before:left-[calc(49%-42px)]"
      onClick={(e: React.MouseEvent<HTMLDivElement>) => pressed(id)}
      onContextMenu={() => false}
    >
      {showRanking && (
        <span
          className={`absolute top-1 -left-2 rounded-full ${
            isAdmin
              ? rank
                ? rank <= 5
                  ? "bg-chart_gold"
                  : "bg-red"
                : null
              : "bg-red"
          } w-[21px] h-[21px] text-center text-sm text-white font-thin content-center`}
        >
          {rank}
        </span>
      )}
      <img
        className="bg-no-repeat sm:self-center w-[78px] h-[78px] pointer-events-none relative"
        src={icon_url}
        alt={name}
      />
      <Tooltip
        content={name}
        placement={"top"}
        placing="0px"
        customClass="max-w-[80px]"
      >
        <span className="display-[-webkit-box]  font-normal text-[10px] lg:text-[14px] sm:font-bold leading-[1.2] text-white text-balance overflow-hidden text-ellipsis line-clamp-2">
          {truncatedName}
        </span>
      </Tooltip>
      {showStatus && (
        <div
          className={`absolute bottom-[-10px] uppercase p-[2px] w-[80px] text-white rounded-[5px] ${
            String(statusText).toLowerCase() === "complete"
              ? "bg-green"
              : String(statusText).toLocaleLowerCase() === "in progress"
              ? "bg-chart_gold"
              : "bg-darkGray"
          }  border border-[#fff] radius-[5px] text-[10px] font-[600]`}
        >
          {statusText}
        </div>
      )}
    </div>
  );
};

export default CareerItem;
