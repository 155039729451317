import { useQuery } from "@tanstack/react-query";
import GlimpseCard from "components/card/card.component";
import DughnutComponent from "components/charts/doughnut.component";
import React from "react";
import { getDeeperDiveSideData } from "services/deeper-dive.service";

const DeepDiveSideData: React.FC = () => {
  const { data: deeperDiveData } = useQuery({
    queryKey: ["deeperDiveData"],
    queryFn: () => getDeeperDiveSideData(),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  // calculate center data
  const videoProgressPercentage =
    deeperDiveData?.preview_progress.preview_completed_percentage ?? 0;

  const previewCenterData = `${deeperDiveData?.preview_progress.total_previewed_count}/${deeperDiveData?.preview_progress.total_unlocked_career_count}`;

  const deeperDivePercentage =
    deeperDiveData?.deep_dive_progress?.deep_dive_progress_percent ?? 0;

  const deeperdiveCenterData = `${deeperDiveData?.deep_dive_progress.completed_deep_dive_career_count}/${deeperDiveData?.deep_dive_progress.total_deep_dive_career_count}`;

  //   OVERVIEW CHART PERCENTAGE
  const chartDataPreviewProgress = {
    datasets: [
      {
        data: [videoProgressPercentage, 100 - videoProgressPercentage],
        backgroundColor: ["#06A77D", "#E2E3E6"],
      },
    ],
  };

  const chartDataDeeperDiveProgress = {
    datasets: [
      {
        data: [deeperDivePercentage, 100 - deeperDivePercentage],
        backgroundColor: ["#D4B07D", "#E2E3E6"],
      },
    ],
  };

  return (
    <>
      {deeperDiveData && (
        <div className="flex flex-row gap-5 p-2 overflow-auto lg:flex-col charts h-[160px] lg:h-auto">
          <GlimpseCard
            className="w-[100%] h-[100%] "
            title="Career Video Progress"
            titleClass="text-[10px] font-normal text-center  lg:text-lg lg:font-bold text-headerBlue"
            childrenClass="w-[100%] h-[100%]"
          >
            <div className="flex items-center justify-center min-w-[80px] h-[80px] lg:min-w-[133px] lg:h-[133px]">
              <DughnutComponent
                centerPercentage={true}
                ratioData={previewCenterData}
                centerValue={`${videoProgressPercentage}% `}
                data={chartDataPreviewProgress}
              />
            </div>
          </GlimpseCard>
          <GlimpseCard
            className="w-[100%] h-[100%] "
            title="Deeper Dive Progress"
            titleClass="text-[10px] font-normal text-center  lg:text-lg lg:font-bold text-headerBlue"
            childrenClass="w-[100%] h-[100%]"
          >
            <div className="flex items-center justify-center min-w-[80px] h-[80px] lg:min-w-[133px] lg:h-[133px]">
              <DughnutComponent
                centerPercentage={true}
                ratioData={deeperdiveCenterData}
                centerValue={`${deeperDivePercentage.toFixed(0)}%`}
                data={chartDataDeeperDiveProgress}
              />
            </div>
          </GlimpseCard>
          <GlimpseCard
            className="w-[100%] h-[100%] "
            title="Top 3"
            titleClass="text-sm font-normal text-center lg:text-xl lg:font-bold text-headerBlue"
            childrenClass="w-[100%] h-[100%]"
          >
            <div className="flex justify-around gap-1 py-2 lg:py-4">
              {deeperDiveData.top_three_careers.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="flex flex-col justify-center gap-2 text-center w-[60px] ">
                      <img
                        className="sm:self-center w-[40px] md:w-[50px] h-[40px] md:h-[50px]"
                        src={item.icon_url}
                        alt={item.name}
                        title={item.name}
                      />
                      <span className=" text-[10px] md:text-xs text-headerBlue text-semibold">
                        {item.name}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </GlimpseCard>
          <GlimpseCard
            // className="w-[180px] h-[160px] lg:w-[233px] lg:h-[100px]"
            title="My Career Awareness"
            titleClass="text-sm font-normal text-center  lg:text-xl lg:font-bold text-headerBlue"
            childrenClass="min-w-[80px] "
          >
            <div className="flex justify-center text-xl font-bold">
              {deeperDiveData.my_career_ig_level ? (
                <>
                  <span>
                    <img
                      key={deeperDiveData.my_career_ig_level.slug}
                      src={`/assets/icons/${deeperDiveData.my_career_ig_level.slug}_badge.png`}
                      className="flex min-w-[100px] w-[200px] h-auto pl-2"
                      alt={deeperDiveData.my_career_ig_level?.title}
                      title={deeperDiveData.my_career_ig_level?.title}
                    />
                  </span>
                  {/* <span className="capitalize">
                    {deeperDiveData.my_career_ig_level?.title}
                  </span> */}
                </>
              ) : (
                <span className="text-sm font-light">No earned badges yet</span>
              )}
            </div>
          </GlimpseCard>
          {/* <GlimpseCard className="w-[180px] h-[160px] lg:w-[233px] lg:h-[180px]">
            <div className="text-sm font-normal text-left lg:text-xl lg:font-bold lg:text-center text-headerBlue">
              Top 3
            </div>
            <div className="flex gap-2 py-2">
              {[1, 2, 3].map((item) => {
                return (
                  <div className="flex flex-col justify-center text-center">
                    <img
                      src="/assets/icons/boy_with_glass.svg"
                      alt="Top three Career"
                    />
                    <span>Demo</span>
                  </div>
                );
              })}
            </div>
          </GlimpseCard>
          <GlimpseCard className="w-[180px] h-[160px] lg:w-[233px] lg:h-[100px]">
            <div className="text-sm font-normal text-left lg:text-xl lg:font-bold lg:text-center text-headerBlue">
              My Career Awareness
            </div>
            <div className="flex justify-center text-xl font-bold">
              <span>
                <img
                  src="/assets/icons/trophy.svg"
                  className="w-[32px] h-[32px]"
                />
              </span>
              <span>Gold Level</span>
            </div>
          </GlimpseCard> */}
        </div>
      )}
    </>
  );
};

export default DeepDiveSideData;
