import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass } from "swiper";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { IOnBoardResult } from "interfaces/OnbaordingInterface";
import VideoStreaming from "components/video-streaming-onboard/video-streaming.component";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ICarouselProps {
  items: IOnBoardResult[];
  currentSlide: number;
  onBuffer: () => void;
  onPaused: () => void;
  onError: () => void;
  onStart: () => void;
  onProgress: (data: any) => void;
  onEnd: () => void;
  onSeek?: () => void;
  enableNext?: boolean;
  handleSortNow?: () => void;
  setMobileIndex: any;
  count: number;
}

const HorizontolCarousel = ({
  items,
  currentSlide,
  onBuffer,
  onPaused,
  onError,
  onStart,
  onProgress,
  onEnd,
  onSeek,
  enableNext,
  handleSortNow,
  setMobileIndex,
  count,
}: ICarouselProps) => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const handleSlideChange = (swiperRef: SwiperClass) => {
    if (swiperRef) {
      setActiveIndex(swiperRef.realIndex);
      setMobileIndex(swiperRef.realIndex);
      setCurrentIndex(swiperRef.realIndex);
    }
  };

  return (
    <div className="w-full mx-auto h-screen max-h-[calc(100vh-110px)] my-16">
      <Swiper
        onSwiper={(swiper: SwiperClass) => setSwiperRef(swiper)}
        onSlideChange={handleSlideChange}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={false}
        slidesPerView={"auto"}
        initialSlide={0}
        spaceBetween={100}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: false,
        }}
        modules={[EffectCoverflow, Navigation, Pagination]}
        breakpoints={{
          560: { slidesPerView: 2.5 },
          768: { slidesPerView: 3 },
          1024: { slidesPerView: 3.5 },
        }}
      >
        {items.map((item, index) => (
          <SwiperSlide
            key={index}
            className={`relative transition-all duration-300 ease-in-out ${
              activeIndex !== index && !item.is_previewed
                ? "filter blur-[2px]"
                : ""
            }`}
          >
            <div className="relative w-max">
              <VideoStreaming
                videoRef={true}
                isPaused={activeIndex === index}
                videoStatus={item?.cover_video.deeper_dive_status}
                currentIndex={index}
                currentSlide={currentSlide}
                enableButtons={false}
                header={{
                  title: item?.career.name,
                  subtitle: item?.career.superpower,
                  children: (
                    <span className="flex w-[300px] max-w-[297px] sm:w-[270px]">
                      {item?.career.description}
                    </span>
                  ),
                  image: item?.career.icon_url,
                }}
                video_url={item?.cover_video.expert_voice.voice_url}
                thumbnail={item?.cover_video.expert_voice.voice_thumbnail}
                key={index}
                onBuffer={onBuffer}
                onStart={onStart}
                onProgress={onProgress}
                onPaused={onPaused}
                onEnd={onEnd}
                onError={onError}
                onSeek={onSeek}
                enableNext={enableNext}
                handleSortNow={handleSortNow}
                className="!w-[296px] !h-[430px]"
                popupClass="relative sm:grid sm:grid-cols-2 "
              ></VideoStreaming>

              {/* Show Buttons Only for Active Slide */}
              {activeIndex === index && (
                <>
                  <button
                    className="absolute top-[58%] left-[-77px] transform -translate-y-1/2 z-10 bg-green rounded-full p-2"
                    onClick={() => swiperRef?.slidePrev()}
                  >
                    <img
                      src="/assets/svg/prev-icon.svg"
                      alt="Previous"
                      className="w-8 h-8"
                    />
                  </button>
                  {count !== index + 1 ? (
                    <button
                      className={`absolute top-[58%] right-[-37px] transform -translate-y-1/2 z-10 bg-green rounded-full p-2 ${
                        !enableNext
                          ? " cursor-not-allowed opacity-40"
                          : " cursor-pointer"
                      }`}
                      onClick={() => swiperRef?.slideNext()}
                      disabled={!enableNext}
                    >
                      <img
                        src="/assets/svg/next-icon.svg"
                        alt="Next"
                        className="w-8 h-8"
                      />
                    </button>
                  ) : (
                    <button
                      className="absolute top-[58%] right-[-68px] transform -translate-y-1/2 z-10 bg-green px-4 py-2 rounded-3xl"
                      onClick={() => navigate("/on-boarding/complete")}
                    >
                      <div className="flex items-center gap-2 text-white">
                        <p>Finish</p>
                        <img
                          src="/assets/svg/next-icon.svg"
                          alt="Next"
                          className="w-4 h-4"
                        />
                      </div>
                    </button>
                  )}
                </>
              )}
            </div>
          </SwiperSlide>
        ))}

        <div className="flex justify-center space-x-2 mt-6 mr-[60px]">
          {Array.from(Array(count)).map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`h-3 rounded-full transition-all duration-300 ${
                currentIndex === index ? "w-6 bg-green" : "w-3 bg-gray"
              }`}
            ></button>
          ))}
        </div>
      </Swiper>
    </div>
  );
};

export default HorizontolCarousel;
