import { IGlimpseHeaderProps } from "interfaces/CareerHeaderInterface";
import React from "react";

const GlimpseHeader: React.FC<IGlimpseHeaderProps> = ({
  children,
  title,
  subtitle,
  image,
  userRole,
  editCareer,
  careerStatus,
  setArchiveAlert,
  titleClass,
  subTitleClass,
}) => {
  return (
    <div className="w-full flex justify-between max-w-[983px] min-h-[65px] flex-col md:flex-row ">
      <div className="flex  sm:max-w-full sm:min-w-[350px] order-2 md:order-1 ">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <div className="image min-w-[60px] flex items-center">
              <img src={image} alt="user_image" className="w-16 h-16" />
            </div>
            <div className="block p-3 text-headerBlue">
              <p className={`font-bold ${titleClass}`}>{title}</p>
              <p className={`font-normal ${subTitleClass}`}>{subtitle}</p>
            </div>
          </div>
          {children && (
            <div className="font-normal text-[16px] pb-4">
              {children}
            </div>
          )}
        </div>
      </div>

      {userRole === "super" && (
        <div className="flex flex-row items-center justify-start order-1 gap-4 pb-4 md:pb-0 md:justify-end md:flex-col xl:justify-between xl:flex-row md:order-2">
          {careerStatus === "published" && (
            <button
              type="button"
              onClick={() => setArchiveAlert && setArchiveAlert(true)}
              className="text-headerBlue text-center bg-white border border-headerBlue  gap-3 pl-1.5 pt-2.5 pr-1.5 pb-2.5 w-[135px] rounded-full text-sm font-normal leading-5 h-[40px]"
            >
              Archive Career
            </button>
          )}

          <button
            type="button"
            onClick={editCareer}
            className="text-white text-center bg-green gap-3 pl-1.5 pt-2.5 pr-1.5 pb-2.5 w-[135px] rounded-full text-sm font-normal leading-5 h-[40px]"
          >
            Edit Career
          </button>
        </div>
      )}
    </div>
  );
};

export default GlimpseHeader;
