import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useLoginMutation } from "store/slices/auth/login-slice";
import { RootState } from "../../store/glimpse-store";
import { setCredentials } from "../../store/slices/auth/auth-slice";
import router from "../../routers";
import { displayError } from "../../components/Toast/toast.component";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ILoginUser } from "interfaces/user.interface";
import GlimpseInputBox from "components/input/formik-input";
import GlimpseCard from "components/card/card.component";
import { emailRegex } from "constants/regex";

const initialValues = {
  email: "",
  password: "",
};

const LoginScreen: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();

  const { userInfo } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  const submitHandler = async (values: ILoginUser) => {
    try {
      let reqBody: ILoginUser = {
        email: values.email,
        password: values.password,
      };
      let response = await login(reqBody).unwrap();
      await dispatch(setCredentials({ ...response }));
      if (response.user.role === "super") {
        navigate("/admin-dashboard");
      } else if (response.user.role === "partner") {
        navigate("/partner-dashboard");
      } else {
        navigate("/user-careers?tab=preview");
      }
    } catch (error) {
      displayError(
        "Unable to login, Please verify your username and password."
      );
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .matches(emailRegex, "Invalid email Address")
      .required("Email is required..!"),
    password: Yup.string().required("Password is required..!"),
  });

  return (
    <>
      <div className="flex flex-col px-6 py-12 lg:px-8 bg-background h-[calc(100vh-90px)] overflow-auto ">
        <div className="topic">
          <h2 className="mt-5 text-2xl font-bold tracking-tight text-center md:text-4xl text-headerBlue">
            Let’s Start Learning
          </h2>
          <p className="pt-2 text-sm font-bold tracking-tight text-center ">
            If you have an account sign in!
          </p>
        </div>
        <div className="flex justify-center py-4">
          <GlimpseCard
            className="bg-white max-w-[343px] md:max-w-[717px]"
            childrenClass="flex flex-col justify-center md:flex-row md:justify-between p-[20px]"
          >
            <div className="flex flex-col justify-center md:w-[300px]">
              <img
                className="h-[90px] w-[130px] self-center"
                src="/assets/images/GlimpseLogo.svg"
                alt="glimpse_logo"
              />
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitHandler}
              >
                {({ isSubmitting }) => (
                  <Form autoComplete="off">
                    <div>
                      <GlimpseInputBox
                        type="text"
                        id="email"
                        label="Email"
                        as="input"
                        name="email"
                        placeholder="Email Address"
                        maxLength={50}
                        sanitized={true}
                      />
                    </div>
                    <div className="mt-5">
                      <GlimpseInputBox
                        type="password"
                        id="password"
                        label="Password"
                        as="input"
                        name="password"
                        placeholder="Password"
                        isPassword={true}
                        maxLength={50}
                        sanitized={true}
                      />
                    </div>
                    <div
                      className="mt-2 font-bold text-right cursor-pointer text-md text-green"
                      onClick={() => navigate("/forgot-password")}
                    >
                      <span className="text-sm">Forgot Password?</span>
                    </div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="text-white w-full text-center text-base font-semibold leading-4 tracking-wide capitalize whitespace-nowrap justify-center items-center bg-green self-stretch mt-8 py-3 rounded-[100px] max-md:px-5"
                    >
                      {isLoading ? "Loading..." : "Sign In"}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="flex justify-center py-10 text-[12px] font-semibold md:hidden">
              <img src="/assets/svg/horizontal-line.svg" alt="line" />
              <span className="px-4">Or</span>
              <img src="/assets/svg/horizontal-line.svg" alt="line" />
            </div>
            <div className="md:flex flex-col justify-center items-center text-[12px] font-semibold hidden px-[25px]">
              <span className="bg-sky-900 bg-opacity-10 shrink-0 h-36 max-md:hidden w-[2px]" />
              <span className="flex justify-center py-4">Or</span>
              <span className="bg-sky-900 bg-opacity-10 shrink-0 h-36 max-md:hidden w-[2px]" />
            </div>
            <div className="flex flex-col justify-center md:w-[244px]">
              <div className="self-center text-base font-bold leading-6 text-center text-headerBlue">
                Choose from one of the following options.
              </div>
              <div
                className="flex bg-lightBlue h-[80px] w-full mt-2 rounded-xl justify-between cursor-pointer"
                onClick={() =>
                  // navigate("/register", {
                  //   state: {
                  //     type: 2,
                  //   },
                  // })
                  navigate("/register?type=2")
                }
              >
                <div className="flex flex-col content-center p-3">
                  <p className="font-semibold text-white md:text-md xl:text-xl">
                    Redeem Code
                  </p>
                  <p className="text-xs text-white">
                    Received a code? Click here to get started!
                  </p>
                </div>
                <div>
                  <div className="h-[80px] w-[80px] flex-shrink-0">
                    <svg
                      width="80"
                      height="80"
                      viewBox="0 0 64 70"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="rounded-xl"
                    >
                      <circle cx="45" cy="36" r="45" fill="#529C82" />
                      <circle
                        cx="44.5872"
                        cy="35.5871"
                        r="36.3303"
                        fill="#6CBEA1"
                      />
                      <line
                        x1="26.8832"
                        y1="39.7157"
                        x2="35.5046"
                        y2="48.3371"
                        stroke="#F4EDDB"
                        strokeWidth="17"
                        strokeLinecap="round"
                      />
                      <line
                        x1="63.5781"
                        y1="24.4888"
                        x2="37.6172"
                        y2="50.4497"
                        stroke="#F4EDDB"
                        strokeWidth="17"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div
                className="flex bg-ruddyPink h-[80px] w-full mt-6 rounded-xl justify-between cursor-pointer"
                onClick={() => navigate("/register?type=1")}
              >
                <div className="flex flex-col p-3 content-right">
                  <p className="font-semibold text-white md:text-md xl:text-xl">
                    Purchase Code
                  </p>
                  <p className="text-xs text-white">
                    Help someone discover their future.
                  </p>
                </div>
                <div>
                  <div className="h-[80px] w-[80px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="80"
                      height="80"
                      viewBox="0 0 65 70"
                      fill="none"
                      className="rounded-xl"
                    >
                      <path
                        d="M4.70501 80.6381L41.3717 87.1498L41.0474 32.8222L0 25.1844L4.70501 80.6381Z"
                        fill="#516268"
                      />
                      <path
                        d="M77.0464 80.2058L41.3716 87.1498L41.0473 32.8222L81.9999 24.6948L77.0464 80.2058Z"
                        fill="#3B464A"
                      />
                      <path
                        d="M82 24.6948L41.0474 32.8222L0 25.1844L40.9531 17.0575L82 24.6948Z"
                        fill="#516268"
                      />
                      <path
                        d="M14.9449 82.4565L13.4046 27.6086L53.3133 18.8331L65.7957 21.3186L25.0142 29.4557L27.1632 84.6493L14.9449 82.4565Z"
                        fill="#98C4CD"
                      />
                      <path
                        d="M67.3224 82.2459L69.7252 27.6777L29.7324 18.9741L17.2806 21.6084L58.1316 29.7095L55.1249 84.6313L67.3224 82.2459Z"
                        fill="#98C4CD"
                      />
                      <path
                        d="M69.4689 2.56175C49.1621 -9.59485 39.6062 25.462 39.6062 25.462C85.7267 17.4406 69.4689 2.56175 69.4689 2.56175ZM51.634 19.5955C51.634 19.5955 56.2169 7.29009 65.7145 7.68781C65.7145 7.68781 71.0501 15.719 51.634 19.5955Z"
                        fill="#98C4CD"
                      />
                      <path
                        d="M12.2154 2.90404C12.2154 2.90404 -3.86339 17.9759 42.3495 25.446C42.3495 25.446 32.3754 -9.4942 12.2154 2.90404ZM17.7844 4.48809C31.1614 3.49866 33.8836 17.0998 33.8836 17.0998C20.2208 13.6701 17.7844 4.48809 17.7844 4.48809Z"
                        fill="#98C4CD"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <hr />
              </div>
              <div className="self-stretch mt-4 text-base font-bold leading-6 text-center text-headerBlue whitespace-nowrap max-md:mt-10">
                Create your own account.
              </div>
              <button
                type="button"
                onClick={() => navigate("/register")}
                className="text-zinc-700 text-center text-base font-semibold leading-4 tracking-wide capitalize whitespace-nowrap items-stretch border border-[color:var(--Button\_Outline,#56686F)] self-stretch justify-center mt-[17px] py-3 rounded-[100px] border-solid max-md:pl-6 max-md:pr-6"
              >
                Create New Account
              </button>
            </div>
          </GlimpseCard>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
