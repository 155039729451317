import React from "react";
import { badgesImage } from "utills/image";
const EarnBadges = () => {
  return (
    <div>
      <div className="text-blue font-bold text-[24px] lg:mt-[-12px] text-center leading-[30px]">
        Earn badges by watching more career video previews and completing deeper
        dives.
      </div>
      <div className="flex justify-center items-center w-full py-6">
        <img src={badgesImage} alt="" />
      </div>
    </div>
  );
};

export default EarnBadges;
